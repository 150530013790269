<template>
        
    <section>
        <b-overlay :show="loading"
                   :variant="variant"
                   :opacity="opacity"
                   :blur="blur"
                   spinner-variant="primary"
                   spinner-type="grow"
                   spinner-large
                   class="loaderPreview"
                   rounded="sm">


            <!--<loader v-if="loading"
         object="#161d31" color1="#d0d2d6" color2="#615ac7" size="5" speed="2" bg="#283046" objectbg="#999793" opacity="80" disableScrolling="false" name="box"></loader>-->


            <b-row v-if="(meta.breadcrumb || meta.pageTitle) && funnelName"
                   class="content-header">

                <b-col class="content-header-left mb-2"
                       cols="12"
                       md="8">
                    <b-row class="breadcrumbs-top">
                        <b-col cols="12">
                            <h2 class="content-header-title float-left pr-1 mb-0">
                                Project Detail
                            </h2>
                            <div class="breadcrumb-wrapper">
                                <b-breadcrumb>
                                    <b-breadcrumb-item to="/">
                                        <feather-icon icon="HomeIcon"
                                                      size="16"
                                                      class="align-text-top" />
                                    </b-breadcrumb-item>
                                    <b-breadcrumb-item v-for="item in meta.breadcrumb"
                                                       :key="item.text"
                                                       :active="item.active"
                                                       :to="item.to">
                                        {{ item.text }}
                                    </b-breadcrumb-item>

                                    <b-breadcrumb-item :active="breadcrumbActive">
                                        {{funnelName}}
                                    </b-breadcrumb-item>
                                    <b-breadcrumb-item v-if="(UserFunnelFirstStep && (UserFunnelFirstStep.UserFunnelStepVariationId==null || UserFunnelFirstStep.UserFunnelStepVariationId < 0)) || (UserFunnelSecondStep && (UserFunnelSecondStep.UserFunnelStepVariationId==null || UserFunnelSecondStep.UserFunnelStepVariationId < 0))">
                                        <b-link v-if="showFirstStep"
                                                @click="LoadTemplates"
                                                data-stepindex="0"
                                                :data-Id="UserFunnelFirstStep.Id"
                                                :data-isLoaded="UserFunnelFirstStep.UserFunnelStepVariationId <= 0 ? '0' : '1'"
                                                :data-order="UserFunnelFirstStep.Order"
                                                :data-temptypeId="UserFunnelFirstStep.TemplateTypeId"
                                                :data-userStepId="UserFunnelFirstStep.Id"
                                                :data-templateId="UserFunnelFirstStep.TemplateId">
                                            {{UserFunnelFirstStep.Name}}
                                        </b-link>

                                        <b-link v-if="showSecondStep"
                                                @click="LoadTemplates"
                                                data-stepindex="1"
                                                :data-Id="UserFunnelSecondStep.Id"
                                                :data-isLoaded="UserFunnelSecondStep.UserFunnelStepVariationId <= 0 ? '0' : '1'"
                                                :data-order="UserFunnelSecondStep.Order"
                                                :data-temptypeId="UserFunnelSecondStep.TemplateTypeId"
                                                :data-userStepId="UserFunnelSecondStep.Id"
                                                :data-templateId="UserFunnelSecondStep.TemplateId">
                                            {{UserFunnelSecondStep.Name}}
                                        </b-link>
                                    </b-breadcrumb-item>

                                </b-breadcrumb>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

                <!-- Content Right -->
                <b-col class="content-header-right text-md-right d-md-block d-none mb-1"
                       md="4"
                       cols="12">


                    <b-button title="Copy PDF URL"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-tooltip.hover.v-secondary
                              variant="flat-secondary"
                              v-if="funnelDef && UserFunnelFirstStep.TemplateTypeId != 22 && funnelDef.PDFFileURL"
                              v-clipboard:copy="funnelDef.PDFFileURL"
                              v-clipboard:success="onPDFCopy"
                              v-clipboard:error="onPDFError"
                              class="btn-icon mr-1">
                        <feather-icon icon="FileIcon" />
                    </b-button>

                    <b-button title="Copy PDF URL"
                              v-if="funnelDef && UserFunnelFirstStep.TemplateTypeId != 22 && !funnelDef.PDFFileURL"
                              @click="attachPDFMsg"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-tooltip.hover.v-secondary
                              variant="flat-secondary"
                              class="btn-icon mr-1">
                        <feather-icon icon="FileIcon" />
                    </b-button>



                    <b-button :to="'/projectcontacts/' + userFunnelId"
                              title="Project Contacts"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-tooltip.hover.v-primary
                              variant="flat-primary"
                              class="btn-icon mr-1">
                        <feather-icon icon="UsersIcon" />
                    </b-button>


                    <b-button title="Copy Project URL"
                              v-if="isProjectCompleted && funnelPath"
                              v-clipboard:copy="funnelPath"
                              v-clipboard:success="onPathCopy"
                              v-clipboard:error="onPathError"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-tooltip.hover.v-success
                              variant="flat-success"
                              class="btn-icon mr-1">
                        <feather-icon icon="CopyIcon" />
                    </b-button>

                    <b-button title="Copy Project URL"
                              v-if="!isProjectCompleted"
                              @click="completeProjectMessage"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-tooltip.hover.v-success
                              variant="flat-success"
                              class="btn-icon mr-1">
                        <feather-icon icon="CopyIcon" />
                    </b-button>


                    <b-button title="View Project URL"
                              v-if="isProjectCompleted && funnelPath"
                              :href="funnelPath"
                              target="_blank"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-tooltip.hover.v-warning
                              variant="flat-warning"
                              class="btn-icon mr-1">
                        <feather-icon icon="ExternalLinkIcon" />
                    </b-button>

                    <b-button title="View Project URL"
                              v-if="!isProjectCompleted"
                              @click="completeProjectMessage"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-tooltip.hover.v-warning
                              variant="flat-warning"
                              class="btn-icon mr-1">
                        <feather-icon icon="ExternalLinkIcon" />
                    </b-button>

                    <b-button v-if="UserFunnelFirstStep.TemplateTypeId == 22" title="Terms & Privacy Policy"
                              v-b-modal.projectTermsPrivacyPolicy
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-tooltip.hover.v-info
                              variant="flat-info"
                              class="btn-icon mr-1">
                        <feather-icon icon="FileIcon" />
                    </b-button>

                    <b-button title="Settings"
                              v-b-modal.projectSettingsModal
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-tooltip.hover.v-info
                              variant="flat-primary"
                              class="btn-icon mr-1">
                        <feather-icon icon="SettingsIcon" />
                    </b-button>

                </b-col>
            </b-row>

            <div class="post">
                <div v-if="error" class="error">
                    {{ error }}
                </div>
            </div>

            <div v-if="UserFunnelFirstStep && (UserFunnelFirstStep.UserFunnelStepVariationId==null || UserFunnelFirstStep.UserFunnelStepVariationId < 0)">
                <b-link @click="LoadTemplates"
                        ref="refFirstStep"
                        id="refFirstStep"
                        data-stepindex="0"
                        class="hidden"
                        :data-Id="UserFunnelFirstStep.Id"
                        :data-isLoaded="UserFunnelFirstStep.UserFunnelStepVariationId <= 0 ? '0' : '1'"
                        :data-order="UserFunnelFirstStep.Order"
                        :data-temptypeId="UserFunnelFirstStep.TemplateTypeId"
                        :data-userStepId="UserFunnelFirstStep.Id"
                        :data-templateId="UserFunnelFirstStep.TemplateId">
                    {{UserFunnelFirstStep.Name}}
                </b-link>
                <b-row class="match-height" v-if="showFirstStep">
                    <b-col lg="12">

                        <b-row>
                            <b-col lg="4"
                                   class="custom-col-6"
                                   v-if="UserFunnelFirstStep.UserFunnelStepVariationId <= 0"
                                   v-for="temp in Templates"
                                   v-bind:data="temp"
                                   v-bind:key="temp.Name">

                                <b-card no-body
                                        class="shadow-none">
                                    <b-card-body class="text-center">
                                        <expandable-image class="image"
                                                          :src="WebAPIURL +'/TemplateFiles/' +temp.MainFolder +'/' +temp.ParentFolder +'/' +temp.FolderOne +'/_template_files/thumb.Jpg'" />
                                    </b-card-body>

                                    <b-row class="text-center mx-0">
                                        <b-col cols="12"
                                               class="border-top d-flex align-items-between flex-column py-1">
                                            <h3 class="font-weight-bolder mb-0">
                                                <b-link :data-UserStepId="temp.UserStepId"
                                                        :data-CurrentTabId="UserFunnelFirstStep.Id"
                                                        data-index="0"
                                                        :data-parentFolder="temp.ParentFolder"
                                                        :data-mainFolder="temp.MainFolder"
                                                        :data-childFolder="temp.FolderOne"
                                                        :data-TemplateFunnelId="temp.TemplateFunnelId"
                                                        :data-templateTypeId="temp.TemplateTypeId"
                                                        :data-funnelId="userFunnelId"
                                                        :data-templateFileName="temp.TemplateFileName"
                                                        :data-IsUserTemplate="temp.IsUserTemplate"
                                                        :data-value="temp.Id"
                                                        class="btn mr-1 btn-outline-primary templates"
                                                        @click="UseThisTemplate">
                                                    <i class="fa fa-mouse-pointer"></i>
                                                    <span class="name">Use This</span>
                                                </b-link>

                                                <b-link target="_blank"
                                                        :href="WebAPIURL +'/Home/' +temp.TemplateUrl +'/' +temp.FolderOne"
                                                        class="btn mr-1 btn-outline-success">
                                                    <i class="fa fa-eye"></i>
                                                    <span class="name">Preview</span>
                                                </b-link>
                                            </h3>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                        </b-row>

                    </b-col>
                </b-row>
            </div>

            <div v-if="UserFunnelSecondStep && (UserFunnelSecondStep.UserFunnelStepVariationId==null || UserFunnelSecondStep.UserFunnelStepVariationId < 0)">
                <b-link ref="refSecondStep"
                        id="refSecondStep"
                        @click="LoadTemplates"
                        data-stepindex="1"
                        class="hidden"
                        :data-Id="UserFunnelSecondStep.Id"
                        :data-isLoaded="UserFunnelSecondStep.UserFunnelStepVariationId <= 0 ? '0' : '1'"
                        :data-order="UserFunnelSecondStep.Order"
                        :data-temptypeId="UserFunnelSecondStep.TemplateTypeId"
                        :data-userStepId="UserFunnelSecondStep.Id"
                        :data-templateId="UserFunnelSecondStep.TemplateId">
                    {{UserFunnelSecondStep.Name}}
                </b-link>
                <b-row class="match-height" v-if="showSecondStep">
                    <b-col lg="12">
                        <b-row>
                            <b-col lg="4"
                                   class="custom-col-6"
                                   v-if="UserFunnelSecondStep.UserFunnelStepVariationId <= 0"
                                   v-for="temp in Templates"
                                   v-bind:data="temp"
                                   v-bind:key="temp.Name">
                                <b-card no-body
                                        class="shadow-none">
                                    <b-card-body class="text-center" style="width: 100%">
                                        <expandable-image class="image"
                                                          :src="WebAPIURL +'/TemplateFiles/' +temp.MainFolder +'/' +temp.ParentFolder +'/' +temp.FolderOne +'/_template_files/thumb.Jpg'" />
                                    </b-card-body>

                                    <b-row class="text-center mx-0">
                                        <b-col cols="12"
                                               class="border-top d-flex align-items-between flex-column py-1">
                                            <h3 class="font-weight-bolder mb-0">
                                                <b-link :data-UserStepId="temp.UserStepId"
                                                        :data-CurrentTabId="UserFunnelSecondStep.Id"
                                                        data-index="1"
                                                        :data-parentFolder="temp.ParentFolder"
                                                        :data-mainFolder="temp.MainFolder"
                                                        :data-childFolder="temp.FolderOne"
                                                        :data-TemplateFunnelId="temp.TemplateFunnelId"
                                                        :data-templateTypeId="temp.TemplateTypeId"
                                                        :data-funnelId="userFunnelId"
                                                        :data-templateFileName="temp.TemplateFileName"
                                                        :data-IsUserTemplate="temp.IsUserTemplate"
                                                        :data-value="temp.Id"
                                                        class="btn mr-1 btn-outline-primary templates"
                                                        @click="UseThisTemplate">
                                                    <i class="fa fa-mouse-pointer"></i>
                                                    <span class="name">Use This</span>
                                                </b-link>

                                                <b-link target="_blank"
                                                        :href="WebAPIURL +'/Home/' +temp.TemplateUrl +'/' +temp.FolderOne"
                                                        class="btn mr-1 btn-outline-success">
                                                    <i class="fa fa-eye"></i>
                                                    <span class="name">Preview</span>
                                                </b-link>
                                            </h3>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                        </b-row>

                    </b-col>
                </b-row>
            </div>

            <b-row v-if="UserFunnelFirstStep && UserFunnelFirstStep.UserFunnelStepVariationId > 0 && UserFunnelSecondStep && UserFunnelSecondStep.UserFunnelStepVariationId > 0">

                <b-col lg="6" v-for="(item, index) in UserFunnelSteps"
                       v-bind:data="item"
                       v-bind:key="index">
                    <b-card no-body>
                        <b-card-header>
                            <b-card-title>
                                <b-row>
                                    <b-col lg="6">
                                        {{item.Name}}
                                    </b-col>
                                    <b-col lg="6" class="text-right">

                                        <b-button title="View Step URL/Link"
                                                  :data-stepname="item.Name"
                                                  :data-stepurl="index == 0 ? firstStepURL : secondStepURL"
                                                  :data-customdomainid="item.CustomDomainId"
                                                  :data-customdomainvariationid="item.CustomDomainVariationId"
                                                  :data-stepid="item.Id"
                                                  data-variationid="0"
                                                  :data-currIndex="index"
                                                  @click="OpenSettingsModal"
                                                  v-b-modal.stepSettingsModal
                                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  v-b-tooltip.hover.v-warning
                                                  variant="flat-warning"
                                                  class="btn-icon mr-1">
                                            <feather-icon icon="LinkIcon" />
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-card-title>
                        </b-card-header>
                        <b-card-body>
                            <b-row>
                                <b-col lg="12">
                                    <expandable-image :src="SubDomainURL +'/' +userFilesDir +'/'+CustomUserId+'/' +item.UserFunnelId +'/' +item.Id +'/_template_files_' +item.TemplateFileName.replace('.html','').replace('.json', '') +'/thumb.Jpg?a='+randomStr+''" />
                                </b-col>
                            </b-row>


                        </b-card-body>
                        <b-card-footer class="card-profile">

                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6 class="text-muted font-weight-bolder">
                                        Total Visitors
                                    </h6>
                                    <h3 class="mb-0">
                                        {{ item.StepTotalVisitors }}
                                    </h3>
                                </div>
                                <div>
                                    <b-button variant="text-plain"
                                              class="text-secondary p-0 mr"
                                              :to="'/projectcontacts/' + item.UserFunnelId">
                                        Contacts
                                    </b-button>
                                    <h3 class="mb-0">
                                        {{ item.ContactsCount }}
                                    </h3>
                                </div>
                                <div class="text-right">

                                    <b-button title="Edit Template"
                                              v-b-tooltip.hover.v-primary
                                              :to="'/editproject/' +item.UserFunnelStepVariationId"
                                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                              variant="flat-primary"
                                              class="btn-icon">
                                        <feather-icon icon="EditIcon" />
                                    </b-button>

                                    <b-button title="View"
                                              v-b-tooltip.hover.v-warning
                                              :href="index == 0 ? firstStepPageURL : secondStepPageURL"
                                              target="_blank"
                                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                              variant="flat-warning"
                                              class="btn-icon">
                                        <feather-icon icon="EyeIcon" />
                                    </b-button>

                                    <b-button title="Download HTML"
                                              @click="downloadHTML"
                                              :data-stepid="item.Id"
                                              :data-subDomain="item.FunnelStepPageURL.replace(item.FunnelStepURL,'')"
                                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                              v-b-tooltip.hover.v-info
                                              variant="flat-info"
                                              class="btn-icon">
                                        <feather-icon icon="ArrowDownCircleIcon" />
                                    </b-button>

                                    <b-button title="Reset Template"
                                              @click="resetProjectTemplate"
                                              :data-UserFunnelId="item.UserFunnelId"
                                              :data-UserFunnelStepId="item.Id"
                                              :data-UserFunnelStepVariationId="item.UserFunnelStepVariationId"
                                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                              v-b-tooltip.hover.v-danger
                                              variant="flat-danger"
                                              class="btn-icon">
                                        <feather-icon icon="RefreshCwIcon" />
                                    </b-button>


                                </div>
                            </div>

                        </b-card-footer>
                    </b-card>

                </b-col>



            </b-row>

            <b-tabs v-if="UserFunnelFirstStep">

                <b-tab v-if="UserFunnelFirstStep.TemplateTypeId == 22" title="Product">

                    <b-card class="cardMobile">

                        <b-row v-if="!StepProducts"
                               class="mb-1">
                            <b-col lg="12" class="text-right">
                                <b-button @click="addEditProduct"
                                          data-id="0"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          type="button"
                                          variant="outline-success">
                                    <feather-icon icon="PlusIcon" size="18" />
                                    Add New Product
                                </b-button>
                            </b-col>
                        </b-row>

                        <b-row class="match-height">
                            <b-col lg="12">
                                <b-table striped
                                         hover
                                         caption-top
                                         fixed
                                         bordered
                                         :items="StepProducts"
                                         :fields="prodTablefields"
                                         :search-options="{enabled: true,externalQuery: prodSearchTerm,}"
                                         :pagination-options="{enabled: true,perPage: prodPageLength,}"
                                         show-empty>
                                    <template #empty="scope">
                                        Record doesn't exist
                                    </template>
                                    <template v-slot:cell(Id)="data">
                                        <b-button :data-id="data.item.Id"
                                                  title="Edit"
                                                  @click="addEditProduct"
                                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  v-b-tooltip.hover.v-primary
                                                  variant="flat-primary"
                                                  class="btn-icon">
                                            <feather-icon icon="EditIcon" />
                                        </b-button>

                                        <b-button :data-id="data.item.Id"
                                                  @click="removeProduct"
                                                  title="Delete"
                                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  v-b-tooltip.hover.v-danger
                                                  variant="flat-danger"
                                                  class="btn-icon">
                                            <feather-icon icon="ArchiveIcon" />
                                        </b-button>
                                    </template>
                                </b-table>

                            </b-col>
                        </b-row>

                    </b-card>
                </b-tab>

                <b-tab title="Email Automation">

                    <b-card>
                        <b-row class="mb-1">

                            <b-col lg="12" class="text-right">
                                <b-button data-id="0"
                                          @click="addEditEmail"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          type="button"
                                          variant="outline-success">
                                    <feather-icon icon="PlusIcon" size="18" />
                                    Add New Email
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12">

                                <!--<b-card-body>
                        <div class="d-flex justify-content-between flex-wrap">-->
                                <!-- filter -->
                                <!--<b-form-group label="Search"
                                              label-cols-sm="2"
                                              label-align-sm="left"
                                              label-size="sm"
                                              label-for="filterInput"
                                              class="mb-0">
                                    <b-input-group size="sm">
                                        <b-form-input id="filterInput"
                                                      v-model="filter"
                                                      type="text"
                                                      placeholder="Type to Search" />
                                        <b-input-group-append>
                                            <b-button :disabled="!filter"
                                                      @click="filter = ''">
                                                Clear
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </div>
                        </b-card-body>-->
                                <b-table striped
                                         hover
                                         responsive
                                         bordered
                                         fixed
                                         class="position-relative"
                                         :items="StepFunnelEmails"
                                         :fields="emailTablefields"
                                         show-empty>
                                    <template #empty="scope">
                                        Record doesn't exist
                                    </template>
                                    
                                    <template v-slot:cell(EmailOpenCount)="data">
                                        {{data.item.EmailDeliveryCount > 0? ((data.item.EmailOpenCount /data.item.EmailDeliveryCount) *100).toFixed(2): 0}}
                                    </template>
                                    <template v-slot:cell(EmailClickCount)="data">
                                        {{data.item.EmailDeliveryCount > 0? (data.item.EmailClickCount /data.item.EmailDeliveryCount).toFixed(2) *100: 0}}
                                    </template>
                                    <template v-slot:cell(Status)="data">
                                        {{data.item.IsScheduled ? "Schedule" : "Draft"}}
                                    </template>
                                    <template v-slot:cell(Id)="data">

                                        <b-button :data-id="data.item.Id"
                                                  title="Clone"
                                                  v-b-tooltip.hover.v-info
                                                  @click="cloneFunnelEmail"
                                                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                                  variant="flat-info"
                                                  class="btn-icon">
                                            <feather-icon icon="CopyIcon" />
                                        </b-button>

                                        <b-button :data-id="data.item.Id" 
                                                  @click="addEditEmail"
                                                  title="Edit"
                                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  v-b-tooltip.hover.v-primary
                                                  variant="flat-primary"
                                                  class="btn-icon">
                                            <feather-icon icon="EditIcon" />
                                            <!-- <feather-icon :icon="!data.item.IsScheduled ? 'EditIcon' : 'EyeIcon'" />-->
                                            <!--:title="!data.item.IsScheduled ? 'Edit' : 'View'"-->
                                        </b-button>

                                        <b-button :data-IsScheduled="data.item.IsScheduled" :data-id="data.item.Id"
                                                  @click="deleteStepEmails"
                                                  title="Delete"
                                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  v-b-tooltip.hover.v-danger
                                                  variant="flat-danger"
                                                  class="btn-icon">
                                            <feather-icon icon="ArchiveIcon" />
                                        </b-button>
                                    </template>
                                </b-table>

                                <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                                    <!-- page length -->
                                    <!--<b-form-group label="Per Page"
                                          label-cols="6"
                                          label-align="left"
                                          label-size="sm"
                                          label-for="sortBySelect"
                                          class="text-nowrap mb-md-0 mr-1">
                                <b-form-select id="perPageSelect"
                                               v-model="perPage"
                                               size="sm"
                                               inline
                                               :options="pageOptions" />
                            </b-form-group>-->
                                    <!-- pagination -->
                                    <!--<div>
                            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">-->
                                    <!-- page length -->
                                    <!--<b-form-group label="Per Page"
                                          label-cols="6"
                                          label-align="left"
                                          label-size="sm"
                                          label-for="sortBySelect"
                                          class="text-nowrap mb-md-0 mr-1">
                                <b-form-select id="perPageSelect"
                                               v-model="perPage"
                                               size="sm"
                                               inline
                                               :options="pageOptions" />
                            </b-form-group>-->
                                    <!-- pagination -->
                                    <!--<div>
                                        <b-pagination v-model="currentPage"
                                                      :total-rows="totalRows"
                                                      :per-page="perPage"
                                                      first-number
                                                      last-number
                                                      prev-class="prev-item"
                                                      next-class="next-item"
                                                      class="mb-0">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon"
                                                              size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon"
                                                              size="18" />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </b-card-body>
                            </div>-->
                                </b-card-body>

                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

                <b-tab title="Auto Responders" :active="activeTab === 'AutoResponder'">
                    <!--<b-card>
                <h1>Auto Responders</h1>
            </b-card>-->
                    <b-card v-if="autoResponders">
                        <b-row>
                            <b-col lg="12">
                                <b-form-group label="Auto Responder(Optional) Please note that without auto responders leads will be saved in your Landingly database"
                                              label-for="AutoResponderID">
                                    <select class="form-control"
                                            name="AutoResponderID"
                                            id="AutoResponderID"
                                            v-model="UserFunnelSteps[0].AutoResponderID"
                                            @change="onAutoRespChange($event)">
                                        <option value="0">Select One</option>
                                        <option v-for="auto in autoResponders"
                                                :value="auto.Id">
                                            {{ auto.Name }}
                                        </option>
                                    </select>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12" v-if="showGetResponse">
                                <div v-if="lstResponseAccount.length > 0">
                                    <b-col lg="12">
                                        <b-form-group label="Select a List"
                                                      label-for="ResponseCompainID">
                                            <b-input-group>
                                                <select class="form-control"
                                                        name="ResponseCompainID"
                                                        id="ResponseCompainID"
                                                        v-model="UserFunnelSteps[0].ResponseCompainID">
                                                    <option value="0">Select One</option>
                                                    <optgroup v-for="res in lstResponseAccount"
                                                              label="res.APIKey">
                                                        <option v-if="res.ResponseCampaignList.length > 0"
                                                                v-for="list in res.ResponseCampaignList"
                                                                :value="list.Id">
                                                            {{ list.Name }}
                                                        </option>
                                                    </optgroup>
                                                </select>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="12" class="text-right">
                                        <b-link data-action="AddOther"
                                                @click="loadGetResponseAccount">
                                            Login With Other Account
                                        </b-link>
                                    </b-col>
                                </div>
                                <div v-if="lstResponseAccount.length < 1"
                                     class="text-right">
                                    <b-link class="btn mb-1 btn-success"
                                            data-action="AddOther"
                                            @click="loadGetResponseAccount">
                                        Login to GetResponse
                                    </b-link>
                                </div>
                            </b-col>

                            <b-col lg="12" v-if="showSendLane">
                                <div v-if="lstSendLaneAccount.length > 0">
                                    <b-col lg="12">
                                        <b-form-group label="Select a List"
                                                      label-for="ResponseCompainID">
                                            <b-input-group>
                                                <select class="form-control"
                                                        name="SendLaneListId"
                                                        id="SendLaneListId"
                                                        v-model="UserFunnelSteps[0].SendLaneListId">
                                                    <option value="0">Select One</option>
                                                    <optgroup v-for="account in lstSendLaneAccount"
                                                              :label="'Subdomain: ' + account.SubDomain">
                                                        <option v-if="account.SendLaneLists.length >0"
                                                                v-for="list in account.SendLaneLists"
                                                                :value="list.Id">
                                                            {{ list.Name }}
                                                        </option>
                                                    </optgroup>
                                                </select>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </div>
                                <validation-observer ref="sendLaneRules">
                                    <form ref="SendLaneForm"
                                          id="SendLaneForm"
                                          @submit.stop.prevent>
                                        <b-row>
                                            <b-col lg="6">
                                                <b-form-group>
                                                    <label>SendLane SubDomain</label>
                                                    <validation-provider #default="{ errors }"
                                                                         rules="required"
                                                                         name="SendLane SubDomain">
                                                        <b-input-group>
                                                            <b-form-input id="SendLaneSubDomain"
                                                                          name="SendLaneSubDomain"
                                                                          v-model="SendLaneSubDomain"
                                                                          placeholder="Give SendLane SubDomain..."
                                                                          :state="errors.length > 0 ? false : null" />
                                                            <b-input-group-append is-text>
                                                                .sendlane.com
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col lg="6">
                                                <b-form-group>
                                                    <label>SendLane APIKey</label>
                                                    <validation-provider #default="{ errors }"
                                                                         rules="required"
                                                                         name="SendLane APIKey">
                                                        <b-form-input id="SendLaneAPIKey"
                                                                      name="SendLaneAPIKey"
                                                                      v-model="SendLaneAPIKey"
                                                                      placeholder="Give SendLane APIKey..."
                                                                      :state="errors.length > 0 ? false : null" />
                                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col lg="12">
                                                <b-form-group>
                                                    <label>SendLane HashKey</label>
                                                    <validation-provider #default="{ errors }"
                                                                         rules="required"
                                                                         name="SendLane HashKey">
                                                        <b-input-group>
                                                            <b-form-input id="SendLaneHashKey"
                                                                          name="SendLaneHashKey"
                                                                          v-model="SendLaneHashKey"
                                                                          placeholder="Give SendLane HashKey..."
                                                                          :state="errors.length > 0 ? false : null" />
                                                            <b-input-group-append>
                                                                <b-button @click="loadSendLaneAccount"
                                                                          variant="outline-primary">
                                                                    Load
                                                                </b-button>
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </form>
                                </validation-observer>
                            </b-col>

                            <b-col lg="12" v-if="showMailChimp">
                                <div v-if="lstMailChimpAccount.length > 0">
                                    <b-col lg="12">
                                        <b-form-group label="Select a List"
                                                      label-for="MailChimpListId">
                                            <b-input-group>
                                                <select class="form-control"
                                                        name="MailChimpListId"
                                                        id="MailChimpListId"
                                                        v-model="UserFunnelSteps[0].MailChimpListId">
                                                    <option value="0">Select One</option>
                                                    <optgroup v-for="mail in lstMailChimpAccount"
                                                              label="auto.APIKey">
                                                        <option v-if="mail.MailChimpList.length > 0"
                                                                v-for="list in mail.MailChimpList"
                                                                :value="list.Id">
                                                            {{ list.ListId }}

                                                        </option>
                                                    </optgroup>
                                                </select>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="12" class="text-right">
                                        <b-link data-action="AddOther"
                                                @click="loadMailChimpAccount">
                                            Login With Other Account
                                        </b-link>
                                    </b-col>
                                </div>
                                <div v-if="lstMailChimpAccount.length < 1"
                                     class="text-right">
                                    <b-link class="btn btn-success mb-1"
                                            data-action="Add"
                                            @click="loadMailChimpAccount">
                                        Login to MailChimp
                                    </b-link>
                                </div>
                            </b-col>

                            <b-col lg="12" v-if="showAWeber">
                                <div v-if="lstAWeberAccount.length > 0">
                                    <b-col lg="12">
                                        <b-form-group label="Select a List"
                                                      label-for="AWeberListId">
                                            <b-input-group>
                                                <select class="form-control"
                                                        name="AWeberListId"
                                                        id="AWeberListId"
                                                        v-model="UserFunnelSteps[0].AWeberListId">
                                                    <option value="0">Select One</option>
                                                    <optgroup v-for="account in lstAWeberAccount"
                                                              label="account.Name">
                                                        <option v-if="account.Lists.length > 0"
                                                                v-for="list in account.Lists"
                                                                :value="list.Id">
                                                            {{ list.AWeberId }}
                                                            <!--list.ListId-->
                                                        </option>
                                                    </optgroup>
                                                </select>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="12" class="text-right">
                                        <b-link data-action="AddOther"
                                                @click="loadAWeberAccount">
                                            Login With Other Account
                                        </b-link>
                                    </b-col>
                                </div>
                                <div v-if="lstAWeberAccount.length < 1"
                                     class="text-right">
                                    <b-link class="btn btn-success mb-1"
                                            data-action="Add"
                                            @click="loadAWeberAccount">
                                        Login to AWeber
                                    </b-link>
                                </div>
                            </b-col>

                            <b-col lg="12" class="text-right">
                                <b-link class="btn btn-primary"
                                        @click="updateAutoResponder">
                                    <feather-icon icon="SaveIcon" size="18" />
                                    Save
                                </b-link>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

                <b-tab v-if="UserFunnelFirstStep.TemplateTypeId == 15" title="Web Form">
                    <b-card v-if="webFormCode">
                        <b-row>
                            <b-col lg="12">
                                <b-form-group label="FORM HEADLINE"
                                              label-for="FormHeading">
                                    <b-form-input id="FormHeading"
                                                  name="FormHeading"
                                                  v-model="webFormCode.FormHeading"
                                                  placeholder="Give Form Name..." />
                                </b-form-group>
                            </b-col>

                            <b-col lg="4">
                                <b-form-group>
                                    <label>FORM FIELD</label>
                                    <br />
                                    <label>Email</label>
                                </b-form-group>
                            </b-col>

                            <b-col lg="4">
                                <b-form-group>
                                    <label>Display?</label>
                                    <b-form-checkbox v-model="webFormCode.DisplayEmail"
                                                     disabled
                                                     value="true">
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>

                            <b-col lg="4">
                                <b-form-group>
                                    <label>Required?</label>
                                    <b-form-checkbox v-model="webFormCode.RequiredEmail"
                                                     disabled
                                                     value="true">
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>

                            <b-col lg="4">
                                <b-form-group>
                                    <label>FORM FIELD</label>
                                    <br />
                                    <label>Name</label>
                                </b-form-group>
                            </b-col>

                            <b-col lg="4">
                                <b-form-group>
                                    <label>Display?</label>
                                    <b-form-checkbox v-model="webFormCode.DisplayName"
                                                     value="true">
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>

                            <b-col lg="4">
                                <b-form-group>
                                    <label>Required?</label>
                                    <b-form-checkbox v-model="webFormCode.RequiredName"
                                                     value="true">
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label>FORM TYPE</label>
                                    <div class="demo-inline-spacing">
                                        <b-form-radio @change="onPopupTypeChange($event)"
                                                      id="inline"
                                                      value="inline"
                                                      v-model="webFormCode.PopupType">
                                            Inline
                                        </b-form-radio>
                                        <br />
                                        <b-form-radio id="popup"
                                                      value="popup"
                                                      v-model="webFormCode.PopupType">
                                            Popup
                                        </b-form-radio>
                                    </div>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-alert v-if="webFormCode.PopupType == 'inline'"
                                         variant="primary"
                                         show>
                                    <div class="alert-body">
                                        <span>
                                            This allows you to place and display a
                                            standard form anywhere on your website.
                                        </span>
                                    </div>
                                </b-alert>

                                <b-alert v-if="webFormCode.PopupType == 'popup'"
                                         variant="primary"
                                         show>
                                    <div class="alert-body">
                                        <span>
                                            This allows you to place and display an
                                            overlay on your webste.
                                        </span>
                                    </div>
                                </b-alert>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label>ON SUCCESS</label>
                                    <div class="demo-inline-spacing">
                                        <b-form-radio id="message"
                                                      value="message"
                                                      v-model="webFormCode.RedirectionType">
                                            Message
                                        </b-form-radio>
                                        <br />
                                        <b-form-radio id="redirect"
                                                      value="redirect"
                                                      v-model="webFormCode.RedirectionType">
                                            Redirect
                                        </b-form-radio>
                                    </div>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-alert v-if="webFormCode.RedirectionType == 'message'"
                                         variant="primary"
                                         show>
                                    <div class="alert-body">
                                        <span>
                                            It will show thank you message to
                                            subscriber.
                                        </span>
                                    </div>
                                </b-alert>

                                <b-alert v-if="webFormCode.RedirectionType == 'redirect'"
                                         variant="primary"
                                         show>
                                    <div class="alert-body">
                                        <span>
                                            It will redirect subscriber to your already
                                            setup Thank You / Download Page.
                                        </span>
                                    </div>
                                </b-alert>
                            </b-col>

                            <b-col lg="12" class="text-right">
                                <b-link class="btn mr-1 btn-primary"
                                        @click="saveFormCode">
                                    <feather-icon icon="SaveIcon" size="18" />
                                    Save Settings & Generate Code
                                </b-link>
                            </b-col>

                            <b-col lg="12" v-if="webFormCode.GeneratedHTMLCode">
                                <b-form-group>
                                    <label>YOUR WEB FORM CODE</label>
                                    <b-input-group>
                                        <b-input-group-prepend>
                                            <b-button variant="outline-success"
                                                      v-clipboard:copy="webFormCode.GeneratedHTMLCode"
                                                      v-clipboard:success="onCopy"
                                                      v-clipboard:error="onError">
                                                <feather-icon icon="CopyIcon" />
                                            </b-button>
                                        </b-input-group-prepend>
                                        <b-form-textarea id="textarea-plaintext"
                                                         readonly
                                                         :value="webFormCode.GeneratedHTMLCode"
                                                         rows="3"
                                                         no-resize />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

                <b-tab v-if="funnelDef && funnelDef.IsTwoStepOptin == true" title="Double Optin">
                    <b-card v-if="StepDoubleOptin">
                        <b-row>
                            <validation-observer ref="emailRules">
                                <form ref="CreateDoubleOptinEmail"
                                      id="CreateDoubleOptinEmail"
                                      @submit.stop.prevent="saveDoubleOptinEmail">
                                    <div>
                                        <b-row>
                                            <b-col lg="6">
                                                <b-form-group label="From/SMTP:"
                                                              label-for="SmtpConfigurationId">
                                                    <select class="form-control"
                                                            name="ConfigurationId"
                                                            id="ConfigurationId"
                                                            v-model="StepDoubleOptin.SMTPConfigurationId">
                                                        <option value="0">
                                                            {{ UserFromEmail }}
                                                        </option>
                                                        <option v-for="smtp in UserSmtpConfiguration"
                                                                :value="smtp.Id"
                                                                :data-FromName="smtp.FromName"
                                                                :data-FromEmail="smtp.FromEmail">
                                                            {{ smtp.FromName + " <" + smtp.FromEmail + ">" }}
                                                        </option>
                                                    </select>
                                                </b-form-group>
                                            </b-col>

                                            <input type="hidden"
                                                   id="FunnelEmailId"
                                                   name="FunnelEmailId"
                                                   :value="StepDoubleOptin.Id" />
                                            <input type="hidden"
                                                   name="EmailProjectId"
                                                   id="EmailProjectId"
                                                   :value="userFunnelId" />
                                            <input type="hidden"
                                                   id="EmailUserStepId"
                                                   name="EmailUserStepId"
                                                   :value="CurrStepID" />

                                            <b-col lg="6">
                                                <b-form-group>
                                                    <label for="FromName">From Name<span class="required"> *</span></label>
                                                    <validation-provider #default="{ errors }"
                                                                         rules="required"
                                                                         name="From Name">
                                                        <b-form-input id="FromName"
                                                                      name="FromName"
                                                                      v-model="StepDoubleOptin.FromName"
                                                                      placeholder="Please Enter From Name"
                                                                      :state="errors.length > 0 ? false : null" />
                                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col lg="6">
                                                <b-form-group>
                                                    <label for="Subject">Subject<span class="required"> *</span></label>
                                                    <validation-provider #default="{ errors }"
                                                                         rules="required"
                                                                         name="Subject">
                                                        <b-form-input id="Subject"
                                                                      name="Subject"
                                                                      v-model="StepDoubleOptin.Subject"
                                                                      placeholder="Please Enter Email Subject"
                                                                      :state="errors.length > 0 ? false : null" />
                                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col lg="6">
                                                <b-form-group>

                                                    <label for="ReplyToEmail">Reply To Email<span class="required"> *</span></label>
                                                    <validation-provider #default="{ errors }"
                                                                         rules="required|email"
                                                                         name="Reply To Email">
                                                        <b-form-input id="ReplyToEmail"
                                                                      name="ReplyToEmail"
                                                                      v-model="StepDoubleOptin.ReplyToEmail"
                                                                      placeholder="Please Enter Reply To Email"
                                                                      :state="errors.length > 0 ? false : null" />
                                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col lg="12">
                                                <b-form-group>
                                                    <label label-for="EmailText">Email Body<span class="required"> *</span></label>
                                                    <validation-provider #default="{ errors }"
                                                                         rules="required"
                                                                         name="Email Body">
                                                        <quill-editor id="EmailText"
                                                                      name="EmailText"
                                                                      v-model="StepDoubleOptin.EmailText"
                                                                      :options="snowOption"
                                                                      :class="errors.length > 0 ? 'error' : 'valid'"
                                                                      :state="errors.length > 0 ? false : null" />
                                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                        </b-row>
                                    </div>
                                </form>
                            </validation-observer>
                            <b-col lg="12" class="text-right">
                                <b-link class="btn btn-primary"
                                        @click="saveOptinEmail">
                                    <feather-icon icon="SaveIcon" size="18" />
                                    Save
                                </b-link>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

            </b-tabs>

            <b-modal id="stepSettingsModal"
                     ref="modal"
                     title="Update Step URL"
                     @hidden="resetModal"
                     @ok="handleOk"
                     ok-title="Submit"
                     centered>
                <form ref="CurrStepURLForm" @submit.stop.prevent="handleSubmit">
                    <validation-observer ref="CurrStepURLFormRules">
                        <b-form-group>

                            <label for="CurrStepURL">Step URL<span class="required"> *</span></label>
                            <validation-provider #default="{ errors }"
                                                 rules="required"
                                                 name="Step URL">

                                <b-input-group>


                                    <b-form-input id="CurrStepURL"
                                                  name="CurrStepURL"
                                                  v-model="CurrStepURL"
                                                  placeholder="Please Enter Step URL"
                                                  :state="errors.length > 0 ? false : null" />

                                    <b-input-group-append>
                                        <b-button variant="outline-primary">
                                            <b-link :href="currIndexSettings == 0? firstStepPageURL: secondStepPageURL"
                                                    target="_blank">
                                                <feather-icon icon="ExternalLinkIcon" />
                                            </b-link>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>


                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                            </validation-provider>
                        </b-form-group>
                    </validation-observer>
                </form>
            </b-modal>

            <b-modal id="addEditProductsModal"
                     ref="modal"
                     :title="ProductDetail!=null && ProductDetail.FFProduct.Id>0?'Edit Product':'Add Product'"
                     ok-title="Submit"
                     ok-icon="PlusIcon"
                     @ok="handleFormOk"
                     centered
                     size="lg">
                <validation-observer ref="productRules">
                    <form ref="CreateProductFormFunnel"
                          id="CreateProductFormFunnel"
                          @submit.stop.prevent="saveProduct">
                        <b-tabs>
                            <b-tab title="Basic Info">
                                <div v-if="ProductDetail">
                                    <b-row>
                                        <b-col lg="12">
                                            <b-form-group>
                                                <label for="ProductName">Name<span class="required"> *</span></label>
                                                <validation-provider #default="{ errors }"
                                                                     rules="required"
                                                                     name="Product Name">
                                                    <b-form-input id="ProductName"
                                                                  name="ProductName"
                                                                  v-model="ProductDetail.FFProduct.ProductName"
                                                                  placeholder="Please Enter Product Name"
                                                                  :state="errors.length > 0 ? false : null" />
                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col lg="12">
                                            <b-form-group>
                                                <label for="ProductPrice">Price<span class="required"> *</span></label>
                                                <validation-provider #default="{ errors }"
                                                                     rules="required|min_One_decimal"
                                                                     name="Product Price">
                                                    <b-input-group>
                                                        <b-input-group-prepend is-text>
                                                            {{ ProductDetail.FFProduct.CurrencySymbol }}
                                                        </b-input-group-prepend>

                                                        <b-form-input id="ProductPrice"
                                                                      name="ProductPrice"
                                                                      v-model="ProductDetail.FFProduct.ProductPrice"
                                                                      placeholder="Please Enter Product Price"
                                                                      :state="errors.length > 0 ? false : null" />
                                                        <b-input-group-append is-text>
                                                            {{ ProductDetail.FFProduct.CurrencyCode }}
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col lg="4">
                                            <b-form-group v-if="ProductDetail.PaymentMethod.length > 0">
                                                <label for="PaymentMethodId">Payment Methods<span class="required"> *</span></label>
                                                <validation-provider #default="{ errors }"
                                                                     rules="required"
                                                                     name="CheckOut Options">
                                                    <select class="form-control"
                                                            name="PaymentMethodId"
                                                            id="PaymentMethodId"
                                                            @change="openPaymentProcessor($event)"
                                                            :state="errors.length > 0 ? false : null">
                                                        <option>Select Payment Method</option>
                                                        <option v-for="pay in ProductDetail.PaymentMethod"
                                                                :value="pay.Id"
                                                                :dataName="pay.Code"
                                                                :selected="pay.Id ==ProductDetail.FFProduct.PaymentMethodId">
                                                            {{ pay.Name }}
                                                        </option>
                                                    </select>
                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                </validation-provider>
                                            </b-form-group>

                                            <b-form-group label="Please set your payment configurations in your profile."
                                                          v-if="ProductDetail.PaymentMethod.length < 1">
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="4" v-if="PaymentMethodId==1 || PaymentMethodId=='1' ">
                                            <b-form-group id="PaypalDiv">
                                                <label for="PaypalAccounts">Choose Account<span class="required"> *</span></label>
                                                <validation-provider #default="{ errors }"
                                                                     rules="required"
                                                                     name="PaypalAccounts">
                                                    <select class="form-control"
                                                            name="PaypalAccounts"
                                                            id="PaypalAccounts"
                                                            :class="errors.length > 0 ? 'is-invalid' : 'valid'"
                                                            :state="errors.length > 0 ? false : null">
                                                        <option v-for="pay in ProductDetail.PaypalAccounts"
                                                                :value="pay.Id"
                                                                :selected="pay.Id ==ProductDetail.FFProduct.PaymentProcessorId">
                                                            {{ pay.AccountName }}
                                                        </option>
                                                    </select>
                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="4" v-if="PaymentMethodId==2 || PaymentMethodId=='2' ">
                                            <b-form-group id="stripeDiv">
                                                <label for="StripeAccounts">Choose Account<span class="required"> *</span></label>
                                                <validation-provider #default="{ errors }"
                                                                     rules="required"
                                                                     name="StripeAccounts">
                                                    <select class="form-control"
                                                            name="StripeAccounts"
                                                            id="StripeAccounts"
                                                            :class="errors.length > 0 ? 'is-invalid' : 'valid'"
                                                            :state="errors.length > 0 ? false : null">
                                                        <option v-for="pay in ProductDetail.StripeAccounts"
                                                                :value="pay.Id"
                                                                :selected="pay.Id ==ProductDetail.FFProduct.PaymentProcessorId">
                                                            {{ pay.Email }}
                                                        </option>
                                                    </select>
                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>


                                        <b-col lg="4" v-if="PaymentMethodId==3 || PaymentMethodId=='3' ">
                                            <b-form-group id="authorizenetDiv">
                                                <label for="AuthorizeNetAccounts">Choose Account<span class="required"> *</span></label>
                                                <validation-provider #default="{ errors }"
                                                                     rules="required"
                                                                     name="AuthorizeNetAccounts">
                                                    <select class="form-control"
                                                            name="AuthorizeNetAccounts"
                                                            id="AuthorizeNetAccounts"
                                                            :class="errors.length > 0 ? 'is-invalid' : 'valid'"
                                                            :state="errors.length > 0 ? false : null">
                                                        <option v-for="pay in ProductDetail.AuthorizeNetAccounts"
                                                                :value="pay.Id"
                                                                :selected="pay.Id ==ProductDetail.FFProduct.PaymentProcessorId">
                                                            {{ pay.AccountName }}
                                                        </option>
                                                    </select>
                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="4" v-if="PaymentMethodId !=0 || PaymentMethodId!='0'">
                                            <b-form-group>
                                                <label>Payment Mode</label>
                                                <div class="demo-inline-spacing" style="margin-top: -0.5rem">
                                                    <b-form-radio id="PaymentModeSandbox"
                                                                  value="true"
                                                                  v-model="ProductDetail.FFProduct.IsSandBox">
                                                        Sandbox
                                                    </b-form-radio>
                                                    <br>
                                                    <b-form-radio id="PaymentModeLive"
                                                                  value="false"
                                                                  v-model="ProductDetail.FFProduct.IsSandBox">
                                                        Live
                                                    </b-form-radio>
                                                </div>
                                            </b-form-group>

                                        </b-col>

                                    </b-row>
                                    <b-row v-if="PaymentMethodId==4 || PaymentMethodId=='4' ">
                                        <b-col v-bind:lg="Subscription?'6':'12'">
                                            <b-form-group label="BrainTree Accounts"
                                                          id="braintreeDiv"
                                                          label-for="BrainTree Accounts">

                                                <select class="form-control"
                                                        name="BrainTreeAccounts"
                                                        id="BrainTreeAccounts">
                                                    <option v-for="pay in ProductDetail.BrainTreeAccounts"
                                                            :value="pay.Id"
                                                            :selected="pay.Id ==ProductDetail.FFProduct.PaymentProcessorId">
                                                        {{ pay.AccountName }}
                                                    </option>
                                                </select>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="6" v-if="Subscription">

                                            <b-form-group label="BrainTree Plain ID"
                                                          label-for="PlanId">
                                                <validation-provider #default="{ errors }"
                                                                     rules="required"
                                                                     name="BrainTree Plain ID">
                                                    <b-form-input id="PlanId"
                                                                  name="PlanId"
                                                                  v-model="ProductDetail.FFProduct.PlanId"
                                                                  placeholder="BrainTree Plain ID..."
                                                                  :state="errors.length > 0 ? false : null" />
                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                    </b-row>

                                    <input type="hidden"
                                           id="Id"
                                           name="Id"
                                           :value="ProductDetail.FFProduct.Id" />
                                    <input type="hidden"
                                           id="CustomProductID"
                                           name="CustomProductID"
                                           :value="ProductDetail.FFProduct.CustomProductID" />
                                    <input type="hidden"
                                           id="UserFunnelId"
                                           name="UserFunnelId"
                                           :value="CurrStepID" />
                                    <input type="hidden"
                                           id="UserTabNo"
                                           name="UserTabNo"
                                           :value="CurrStepID" />

                                    <!--<b-row>
                                <b-col lg="12">
                                    <b-form-group label="Is Subscription or Multiple Payments?"
                                                  label-for="ProductIsRecurring">
                                        <b-form-checkbox id="ProductIsRecurring"
                                                         v-model="ProductDetail.FFProduct.ProductIsRecurring"
                                                         name="ProductIsRecurring"
                                                         value="true"
                                                         unchecked-value="false"
                                                         @input="SubscriptionDiv">
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>-->

                                    <div v-if="Subscription">
                                        <b-row>
                                            <b-col lg="6">
                                                <b-form-group>
                                                    <label for="ProductPaymentPeriod">Payment Period<span class="required"> *</span></label>
                                                    <validation-provider #default="{ errors }"
                                                                         rules="required|min_One"
                                                                         name="Payment Period">
                                                        <b-form-input id="ProductPaymentPeriod"
                                                                      name="ProductPaymentPeriod"
                                                                      v-model="ProductDetail.FFProduct.ProductPaymentPeriod"
                                                                      placeholder="Please Enter Payment Period"
                                                                      :state="errors.length > 0 ? false : null" />
                                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="6">
                                                <b-form-group v-if="ProductDetail.PaymentFrequency.length > 0">
                                                    <label for="ProductPaymentFrequency">Payment Frequency<span class="required"> *</span></label>
                                                    <select class="form-control"
                                                            name="ProductPaymentFrequency"
                                                            id="ProductPaymentFrequency">
                                                        <option v-for="freq in ProductDetail.PaymentFrequency"
                                                                :value="freq.Id"
                                                                :selected="freq.Id ==ProductDetail.FFProduct.ProductPaymentFrequency">
                                                            {{ freq.FrequencyName }}
                                                        </option>
                                                    </select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="6">
                                                <b-form-group>
                                                    <label for="ProductNumberOfPayments">No Of Payments<span class="required"> *</span></label>
                                                    <validation-provider #default="{ errors }"
                                                                         rules="required|min_One"
                                                                         name="Number Of Payments">
                                                        <b-form-input id="ProductNumberOfPayments"
                                                                      name="ProductNumberOfPayments"
                                                                      v-model="ProductDetail.FFProduct.ProductNumberOfPayments"
                                                                      placeholder="Please Enter No Of Payments"
                                                                      :state="errors.length > 0 ? false : null" />
                                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="12">
                                                <b-form-group label="Is Trial?"
                                                              label-for="ProductIsTrial">
                                                    <b-form-checkbox id="ProductIsTrial"
                                                                     v-model="ProductDetail.FFProduct.ProductIsTrial"
                                                                     name="ProductIsTrial"
                                                                     value="true"
                                                                     unchecked-value="false"
                                                                     @input="TrialDiv">
                                                    </b-form-checkbox>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="IsTrial">
                                            <b-col lg="6">
                                                <b-form-group>
                                                    <label for="ProductTrialPrice">Trial Price<span class="required"> *</span></label>
                                                    <validation-provider #default="{ errors }"
                                                                         rules="required|min_One_decimal"
                                                                         name="Product Trial Price">
                                                        <b-form-input id="ProductTrialPrice"
                                                                      name="ProductTrialPrice"
                                                                      v-model="ProductDetail.FFProduct.ProductTrialPrice"
                                                                      placeholder="Please Enter Trial Price"
                                                                      :state="errors.length > 0 ? false : null" />
                                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="6">
                                                <b-form-group>
                                                    <label for="ProductTrialPeriod">Trial Period in day(s)<span class="required"> *</span></label>
                                                    <validation-provider #default="{ errors }"
                                                                         rules="required|min_One"
                                                                         name="Trial Period">
                                                        <b-form-input id="ProductTrialPeriod"
                                                                      name="ProductTrialPeriod"
                                                                      v-model="ProductDetail.FFProduct.ProductTrialPeriod"
                                                                      placeholder="Please Enter Trial Period in day(s)"
                                                                      :state="errors.length > 0 ? false : null" />
                                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>

                                                <input type="hidden" id="ProductTrialPeriodFrequency" name="ProductTrialPeriodFrequency" value="1" />
                                            </b-col>

                                        </b-row>
                                    </div>

                                    <b-row>
                                        <b-col lg="12">
                                            <b-form-group label="Product Archived"
                                                          label-for="ProductIsTrial"
                                                          class="hidden">
                                                <div class="demo-inline-spacing">
                                                    <b-form-radio v-model="ProductDetail.FFProduct.ProductArchived"
                                                                  name="ProductArchived"
                                                                  value="true">
                                                        Yes
                                                    </b-form-radio>
                                                    <b-form-radio v-model="ProductDetail.FFProduct.ProductArchived"
                                                                  name="ProductArchived"
                                                                  value="false">
                                                        No
                                                    </b-form-radio>
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-tab>

                            <b-tab title="Order Email Settings">
                                <div v-if="ProductDetail">
                                    <b-row>
                                        <b-col lg="12">
                                            <b-form-group label="Do you want to Send Order Email to Customers?"
                                                          label-for="IsEmailEnabled">
                                                <div class="demo-inline-spacing">
                                                    <b-form-radio v-model="ProductDetail.FFProduct.IsEmailEnabled"
                                                                  name="IsEmailEnabled"
                                                                  value="true"
                                                                  @change="fnEmailEnabled">
                                                        Yes
                                                    </b-form-radio>
                                                    <b-form-radio v-model="ProductDetail.FFProduct.IsEmailEnabled"
                                                                  name="IsEmailEnabled"
                                                                  value="false"
                                                                  @change="fnEmailEnabled">
                                                        No
                                                    </b-form-radio>
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="EmailEnabled == true">
                                        <b-col lg="12">
                                            <b-form-group label="From/SMTP:"
                                                          label-for="SmtpConfigurationId"
                                                          v-if="ProductDetail.FunnelUserSmtpConfiguration.length>0">
                                                <select class="form-control"
                                                        name="SmtpConfigurationId"
                                                        id="SmtpConfigurationId">
                                                    <option v-for="smtp in ProductDetail.FunnelUserSmtpConfiguration"
                                                            :value="smtp.Id"
                                                            :selected="smtp.Id ==ProductDetail.FFProduct.SmtpConfigurationId"
                                                            :data-FromName="smtp.FromName"
                                                            :data-FromEmail="smtp.FromEmail">
                                                        {{ smtp.FromName + " <" + smtp.FromEmail + ">" }}
                                                    </option>
                                                </select>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="12">
                                            <b-form-group>
                                                <label for="Subject">Subject<span class="required"> *</span></label>
                                                <validation-provider #default="{ errors }"
                                                                     rules="required"
                                                                     name="Email Subject">
                                                    <b-form-input id="Subject"
                                                                  name="Subject"
                                                                  v-model="ProductDetail.FFProduct.FulfillmentEmailSubject"
                                                                  placeholder="Please Enter Email Subject"
                                                                  :state="errors.length > 0 ? false : null" />
                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="12">
                                            <b-form-group>
                                                <label for="FulfillmentEmailBody">Email Body<span class="required"> *</span></label>
                                                <validation-provider #default="{ errors }"
                                                                     rules="required"
                                                                     name="Email Body">
                                                    <quill-editor id="FulfillmentEmailBody"
                                                                  name="FulfillmentEmailBody"
                                                                  v-model="ProductDetail.FFProduct.FulfillmentEmailBody"
                                                                  :options="snowOption"
                                                                  :class="errors.length > 0 ? 'error' : 'valid'"
                                                                  :state="errors.length > 0 ? false : null" />
                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-tab>

                            <b-tab title="Checkout Page Settings" v-if="PaymentMethod !='' && PaymentMethod!='PAYPAL'">
                                <b-row>
                                    <b-col lg="6"
                                           class="custom-col-6"
                                           v-for="temp in SalePageTemplates"
                                           v-bind:data="temp"
                                           v-bind:key="temp.Name">

                                        <b-tabs content-class="pt-1" fill end
                                                id="tempVariations"
                                                v-if="CheckoutTempId<1">
                                            <b-tab :title-link-attributes="{style:'background:#' +temp.ColorOne +' !important',}"
                                                   display="block" class="mb-0 varitions-color-tab">
                                                <b-card no-body
                                                        class="shadow-none">
                                                    <b-card-body class="text-center">
                                                        <expandable-image class="image"
                                                                          :src="WebAPIURL +'/TemplateFiles/' +temp.MainFolder +'/' +temp.ParentFolder +'/' +temp.ChildFolder +'/_template_files/thumb.Jpg'" />
                                                    </b-card-body>

                                                    <b-row class="text-center mx-0">
                                                        <b-col cols="12"
                                                               class="border-top d-flex align-items-between flex-column py-1">
                                                            <h3 class="font-weight-bolder mb-0">
                                                                <b-link data-index="0"
                                                                        :data-parentFolder="temp.ParentFolder"
                                                                        :data-mainFolder="temp.MainFolder"
                                                                        :data-templateTypeId="temp.TemplateTypeId"
                                                                        :data-templateFileName="temp.TemplateFileName"
                                                                        :data-templateVariation="temp.ChildFolder"
                                                                        :data-TemplateName="temp.TemplateName"
                                                                        :data-value="temp.Id"
                                                                        class="btn mr-1 btn-outline-primary templates"
                                                                        @click="UseThisCheckoutTemplate">
                                                                    <i class="fa fa-mouse-pointer"></i>
                                                                    <span class="name">Use This</span>
                                                                </b-link>
                                                            </h3>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                            </b-tab>

                                            <b-tab :title-link-attributes="{style:'background:#' +temp.ColorTwo +' !important',}"
                                                   display="block" class="mb-0 varitions-color-tab">
                                                <b-card no-body
                                                        class="shadow-none">
                                                    <b-card-body class="text-center">
                                                        <expandable-image class="image"
                                                                          :src="WebAPIURL +'/TemplateFiles/' +temp.MainFolder +'/' +temp.ParentFolder +'/' +temp.FolderTwo +'/_template_files/thumb.Jpg'" />
                                                    </b-card-body>

                                                    <b-row class="text-center mx-0">
                                                        <b-col cols="12"
                                                               class="border-top d-flex align-items-between flex-column py-1">
                                                            <h3 class="font-weight-bolder mb-0">
                                                                <b-link data-index="0"
                                                                        :data-parentFolder="temp.ParentFolder"
                                                                        :data-mainFolder="temp.MainFolder"
                                                                        :data-templateTypeId="temp.TemplateTypeId"
                                                                        :data-templateFileName="temp.TemplateFileName"
                                                                        :data-templateVariation="temp.FolderTwo"
                                                                        :data-TemplateName="temp.TemplateName"
                                                                        :data-value="temp.Id"
                                                                        class="btn mr-1 btn-outline-primary templates"
                                                                        @click="UseThisCheckoutTemplate">
                                                                    <i class="fa fa-mouse-pointer"></i>
                                                                    <span class="name">Use This</span>
                                                                </b-link>
                                                            </h3>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                            </b-tab>

                                            <b-tab :title-link-attributes="{style:'background:#' +temp.ColorThree +' !important',}"
                                                   display="block" class="mb-0 varitions-color-tab">
                                                <b-card no-body
                                                        class="shadow-none">
                                                    <b-card-body class="text-center">
                                                        <expandable-image class="image"
                                                                          :src="WebAPIURL +'/TemplateFiles/' +temp.MainFolder +'/' +temp.ParentFolder +'/' +temp.FolderThree +'/_template_files/thumb.Jpg'" />
                                                    </b-card-body>

                                                    <b-row class="text-center mx-0">
                                                        <b-col cols="12"
                                                               class="border-top d-flex align-items-between flex-column py-1">
                                                            <h3 class="font-weight-bolder mb-0">
                                                                <b-link data-index="0"
                                                                        :data-parentFolder="temp.ParentFolder"
                                                                        :data-mainFolder="temp.MainFolder"
                                                                        :data-templateTypeId="temp.TemplateTypeId"
                                                                        :data-templateFileName="temp.TemplateFileName"
                                                                        :data-templateVariation="temp.FolderThree"
                                                                        :data-TemplateName="temp.TemplateName"
                                                                        :data-value="temp.Id"
                                                                        class="btn mr-1 btn-outline-primary templates"
                                                                        @click="UseThisCheckoutTemplate">
                                                                    <i class="fa fa-mouse-pointer"></i>
                                                                    <span class="name">Use This</span>
                                                                </b-link>
                                                            </h3>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                            </b-tab>

                                            <b-tab :title-link-attributes="{style:'background:#' +temp.ColorFour +' !important',}"
                                                   display="block" class="mb-0 varitions-color-tab">
                                                <b-card no-body
                                                        class="shadow-none">
                                                    <b-card-body class="text-center">
                                                        <expandable-image class="image"
                                                                          :src="WebAPIURL +'/TemplateFiles/' +temp.MainFolder +'/' +temp.ParentFolder +'/' +temp.FolderFour +'/_template_files/thumb.Jpg'" />
                                                    </b-card-body>

                                                    <b-row class="text-center mx-0">
                                                        <b-col cols="12"
                                                               class="border-top d-flex align-items-between flex-column py-1">
                                                            <h3 class="font-weight-bolder mb-0">
                                                                <b-link data-index="0"
                                                                        :data-parentFolder="temp.ParentFolder"
                                                                        :data-mainFolder="temp.MainFolder"
                                                                        :data-templateTypeId="temp.TemplateTypeId"
                                                                        :data-templateFileName="temp.TemplateFileName"
                                                                        :data-templateVariation="temp.FolderFour"
                                                                        :data-TemplateName="temp.TemplateName"
                                                                        :data-value="temp.Id"
                                                                        class="btn mr-1 btn-outline-primary templates"
                                                                        @click="UseThisCheckoutTemplate">
                                                                    <i class="fa fa-mouse-pointer"></i>
                                                                    <span class="name">Use This</span>
                                                                </b-link>
                                                            </h3>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                            </b-tab>
                                        </b-tabs>
                                        <b-card no-body
                                                v-if="CheckoutTempId>1"
                                                class="shadow-none">
                                            <b-card-body class="text-center">
                                                <expandable-image class="image"
                                                                  :src="WebAPIURL +'/TemplateFiles/' +temp.MainFolder +'/' +temp.ParentFolder +'/' +SalePageVariation +'/_template_files/thumb.Jpg'" />
                                            </b-card-body>

                                            <b-row class="text-center mx-0">
                                                <b-col cols="12"
                                                       class="border-top d-flex align-items-between flex-column py-1">
                                                    <h3 class="font-weight-bolder mb-0">

                                                        <b-link data-index="0"
                                                                :data-parentFolder="temp.ParentFolder"
                                                                :data-mainFolder="temp.MainFolder"
                                                                :data-templateTypeId="temp.TemplateTypeId"
                                                                :data-templateFileName="temp.TemplateFileName"
                                                                :data-TemplateName="temp.TemplateName"
                                                                :data-value="temp.Id"
                                                                class="btn mr-1 btn-danger templates"
                                                                @click="ResetCheckoutTemplate">

                                                            <feather-icon icon="RefreshCwIcon"></feather-icon>
                                                            <span class="name"> Reset Template</span>
                                                        </b-link>
                                                    </h3>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-tab>

                        </b-tabs>
                    </form>
                </validation-observer>

                <slot></slot>
                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="closeProductModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>

                    <b-button @click="saveProduct"
                              v-if="CheckoutTempId>0 || PaymentMethod=='PAYPAL'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="info"
                              type="button">
                        <feather-icon icon="SaveIcon" size="18" />
                        Save
                    </b-button>


                </template>

            </b-modal>

            <b-modal no-close-on-backdrop
                     id="addEditEmailModal"
                     ref="modal"
                     :title="StepFunnelEmailDetail!=null && StepFunnelEmailDetail.FunnelEmail.Id>0?'Edit Automation Email':'Add Automation Email'"
                     ok-title="Submit"
                     @ok="handleEmailFormOk"
                     centered
                     size="lg">
                <validation-observer ref="emailRules">
                    <form ref="CreateEmailFormFunnel"
                          id="CreateEmailFormFunnel"
                          @submit.stop.prevent="saveEmail">
                        <div v-if="StepFunnelEmailDetail">
                            <b-row>
                                <b-col lg="12">
                                    <!--<span  v-if="StepFunnelEmailDetail.FunnelEmail.IsScheduled" style="color: #dc3545; text-align: center; display: block; font-weight: 600; "
                                          >All changes will only affect upcoming subscribers.</span>-->
                                    <b-form-group label="From/SMTP:"
                                                  label-for="SmtpConfigurationId">
                                        <select class="form-control"
                                                name="ConfigurationId"
                                                id="ConfigurationId"
                                                v-model="StepFunnelEmailDetail.FunnelEmail.ConfigurationId">
                                            <option value="0">
                                                Use System Default ({{ StepFunnelEmailDetail.UserFromEmail }})
                                            </option>
                                            <option v-for="smtp in StepFunnelEmailDetail.SmtpConfigurations"
                                                    :value="smtp.Id"
                                                    :data-FromName="smtp.FromName"
                                                    :data-FromEmail="smtp.FromEmail">
                                                {{ smtp.FromName + " <" + smtp.FromEmail + ">" }}
                                            </option>
                                        </select>
                                    </b-form-group>
                                </b-col>

                                <input type="hidden"
                                       id="FunnelEmailId"
                                       name="FunnelEmailId"
                                       :value="StepFunnelEmailDetail.FunnelEmail.Id" />
                                <input type="hidden"
                                       name="EmailProjectId"
                                       id="EmailProjectId"
                                       :value="userFunnelId" />
                                <input type="hidden"
                                       id="EmailUserStepId"
                                       name="EmailUserStepId"
                                       :value="CurrStepID" />
                                <input type="hidden"
                                       id="IsScheduled"
                                       name="IsScheduled"
                                       :value="StepFunnelEmailDetail.FunnelEmail.IsScheduled" />
                                <input type="hidden"
                                       name="AttachmentFolderId"
                                       id="AttachmentFolderId"
                                       :value="StepFunnelEmailDetail.FunnelEmail.AttachmentFolderId" />

                                <b-col lg="12">
                                    <b-form-group>
                                        <label for="FromName">From Name<span class="required"> *</span></label>
                                        <validation-provider #default="{ errors }"
                                                             rules="required"
                                                             name="From Name">
                                            <b-form-input id="FromName"
                                                          name="FromName"
                                                          v-model="StepFunnelEmailDetail.FunnelEmail.FromName"
                                                          placeholder="Please Enter From Name"
                                                          :state="errors.length > 0 ? false : null" />
                                            <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col lg="12">
                                    <b-form-group>
                                        <label for="Subject">Subject<span class="required"> *</span></label>
                                        <validation-provider #default="{ errors }"
                                                             rules="required"
                                                             name="Subject">
                                            <b-form-input id="Subject"
                                                          name="Subject"
                                                          v-model="StepFunnelEmailDetail.FunnelEmail.Subject"
                                                          placeholder="Please Enter Email Subject"
                                                          :state="errors.length > 0 ? false : null" />
                                            <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col lg="12">
                                    <b-form-group>

                                        <label for="ReplyToEmail">Reply To Email<span class="required"> *</span></label>
                                        <validation-provider #default="{ errors }"
                                                             rules="required|email"
                                                             name="Reply To Email">
                                            <b-form-input id="ReplyToEmail"
                                                          name="ReplyToEmail"
                                                          v-model="StepFunnelEmailDetail.FunnelEmail.ReplyToEmail"
                                                          placeholder="Please Enter Reply To Email"
                                                          :state="errors.length > 0 ? false : null" />
                                            <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col lg="4">
                                    <b-form-group label="Choose Delay" label-for="ddlDays">
                                        <select class="form-control"
                                                name="ddlDays"
                                                id="ddlDays"
                                                @change="onDelayChange($event)"
                                                v-model="Delay">
                                            <option value="0">Immediately</option>
                                            <option value="1">After 1 Day</option>
                                            <option value="2">After 2 Day</option>
                                            <option value="3">After 3 Day</option>
                                            <option value="4">After 4 Day</option>
                                            <option value="5">After 5 Day</option>
                                            <option value="6">After 6 Day</option>
                                            <option value="7">After 7 Day</option>
                                            <option value="8">Custom</option>
                                        </select>
                                    </b-form-group>
                                </b-col>

                                <b-col lg="4">
                                    <b-form-group label="Delay Days" label-for="DelayDays">
                                        <select class="form-control"
                                                name="DelayDays"
                                                id="DelayDays"
                                                @change="onDayChange($event)"
                                                v-model="StepFunnelEmailDetail.FunnelEmail.DelayDays">
                                            <option value="0">0</option>
                                            <option v-for="index in 365"
                                                    :key="index"
                                                    :value="index">
                                                {{ index }}
                                            </option>
                                        </select>
                                    </b-form-group>
                                </b-col>

                                <b-col lg="4">
                                    <b-form-group label="Delay Hours" label-for="DelayHours">
                                        <select class="form-control"
                                                name="DelayHours"
                                                id="DelayHours"
                                                v-model="StepFunnelEmailDetail.FunnelEmail.DelayHours">
                                            <option value="0">0</option>
                                            <option v-for="index in 23" :key="index" :value="index">
                                                {{ index }}
                                            </option>
                                        </select>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="12">
                                    <b-form-group>
                                        <div v-if="StepFunnelEmailDetail.FunnelEmail.DelayDays>0 || StepFunnelEmailDetail.FunnelEmail.DelayHours>0">
                                            Email will be sent after {{StepFunnelEmailDetail.FunnelEmail.DelayDays}} day{{StepFunnelEmailDetail.FunnelEmail.DelayDays>1?'s':''}} and {{StepFunnelEmailDetail.FunnelEmail.DelayHours}} hour{{StepFunnelEmailDetail.FunnelEmail.DelayHours>1?'s':''}}
                                        </div>
                                        <div v-if="StepFunnelEmailDetail.FunnelEmail.DelayDays<1 && StepFunnelEmailDetail.FunnelEmail.DelayHours<1">
                                            Email will be sent immediately
                                        </div>
                                    </b-form-group>
                                </b-col>

                                <b-col lg="12">
                                    <b-form-group>
                                        <label label-for="EmailText">Email Body<span class="required"> *</span></label>
                                        <validation-provider #default="{ errors }"
                                                             rules="required"
                                                             name="Email Body">
                                            <quill-editor id="EmailText"
                                                          name="EmailText"
                                                          v-model="StepFunnelEmailDetail.FunnelEmail.EmailText"
                                                          :options="snowOption"
                                                          :class="errors.length > 0 ? 'error' : 'valid'"
                                                          :state="errors.length > 0 ? false : null" />
                                            <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col lg="12">
                                    <b-form-group>
                                        <label>Cam Spam Additional Footer Text(Optional)</label>

                                        <b-form-input id="CamSpam"
                                                      name="CamSpam"
                                                      v-model="StepFunnelEmailDetail.FunnelEmail.CamSpam"
                                                      placeholder="Give CamSpam..." />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </form>
                </validation-observer>

                <slot></slot>
                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="closeEmailModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>

                    <b-button @click="openSendTestEmailModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="info"
                              type="button">
                        <feather-icon icon="MailIcon" size="18" />
                        Send Test Email
                    </b-button>
                    <!--v-if="!StepFunnelEmailDetail.FunnelEmail.IsScheduled"-->
                    <b-button v-if="!hideDraftBtn"
                              @click="saveDraftEmail"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="SaveIcon" size="18" />
                        Save a Draft
                    </b-button>

                    <b-button @click="scheduleEmail"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="SendIcon" size="18" />
                        Save & Schedule
                    </b-button>
                </template>
            </b-modal>

            <b-modal id="sendTestEmailModal"
                     ref="modal"
                     title="Send Test Email"
                     ok-title="Submit"
                     @ok="handleEmailFormOk"
                     @hidden="resetTestEmailModal"
                     centered
                     size="lg">
                <validation-observer ref="testEmailRules">
                    <form ref="TestEmailFormFunnel"
                          id="TestEmailFormFunnel"
                          @submit.stop.prevent>
                        <b-row>
                            <b-col lg="12">
                                <b-form-group>
                                    <label for="ToEmail">To Email<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required|email"
                                                         name="Reply To Email">
                                        <b-form-input id="ToEmail"
                                                      name="ToEmail"
                                                      v-model="TestToEmail"
                                                      placeholder="Please Enter To Email"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </form>
                </validation-observer>

                <slot></slot>
                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="closeTestEmailModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>
                    <b-button @click="sendTestEmail"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="MailIcon" size="18" />
                        Send
                    </b-button>
                </template>
            </b-modal>

            <b-modal id="projectTermsPrivacyPolicy"
                     ref="modal"
                     title="Terms & Privacy Policy"
                     ok-title="Submit"
                     @ok="HandleTermsPrivacyPolicyOk"
                     centered
                     size="lg">
                <validation-observer ref="projectTermsPrivacyPolicyRules">
                    <form ref="projectTermsPrivacyPolicyForm"
                          id="projectTermsPrivacyPolicyForm"
                          @submit.stop.prevent>
                        <b-tabs>
                            <b-tab title="Terms & Conditions">
                                <b-row>
                                    <b-col lg="12">
                                        <b-form-group class="float-right">
                                            <b-button title="Copy Term Conditions URL"
                                                      v-if="termConditionsUrl"
                                                      @click="copyTermToClipboard"
                                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                      v-b-tooltip.hover.v-success
                                                      variant="flat-success"
                                                      class="btn-icon mr-1">
                                                <feather-icon icon="CopyIcon" />
                                            </b-button>
                                            
                                            <b-button title="View Term Conditions URL"
                                                      target="_blank"
                                                      v-if="termConditionsUrl"
                                                      :href="termConditionsUrl"
                                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                      v-b-tooltip.hover.v-warning
                                                      variant="flat-warning"
                                                      class="btn-icon mr-1">
                                                <feather-icon icon="ExternalLinkIcon" />
                                            </b-button>

                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="12">
                                        <b-form-group>
                                            <label for="terms">Terms & Conditions<span class="required"> *</span></label>
                                            <validation-provider #default="{ errors }"
                                                                 rules="required"
                                                                 name="TermsConditions">
                                                <quill-editor id="TermsConditions"
                                                              name="TermsConditions"
                                                              v-model="funnelDef.TermsConditions"
                                                              placeholder="Please Enter Terms Conditions"
                                                              :options="snowOption"
                                                              :class="errors.length > 0 ? 'error' : 'valid'"
                                                              :state="errors.length > 0 ? false : null" />
                                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Privacy Policy">
                                <b-row>
                                    <b-col lg="12">
                                        <b-form-group class="float-right">
                                            <b-button title="Copy Privacy Policy URL"
                                                      v-if="privacyUrl"
                                                      @click="copyPrivacyPolicyToClipboard"
                                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                      v-b-tooltip.hover.v-success
                                                      variant="flat-success"
                                                      class="btn-icon mr-1">
                                                <feather-icon icon="CopyIcon" />
                                            </b-button>
                                            

                                            <b-button title="View Privacy Policy URL"
                                                      target="_blank"
                                                      v-if="privacyUrl"
                                                      :href="privacyUrl"
                                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                      v-b-tooltip.hover.v-warning
                                                      variant="flat-warning"
                                                      class="btn-icon mr-1">
                                                <feather-icon icon="ExternalLinkIcon" />
                                            </b-button>

                                        </b-form-group>
                                        
                                    </b-col>
                                    <b-col lg="12">
                                        <b-form-group>
                                            <label for="PrivacyPolicy">Privacy Policy<span class="required"> *</span></label>
                                            <validation-provider #default="{ errors }"
                                                                 rules="required"
                                                                 name="Privacy Policy">
                                                <quill-editor id="PrivacyPolicy"
                                                              name="PrivacyPolicy"
                                                              v-model="funnelDef.PrivacyPolicy"
                                                              placeholder="Please Enter Privacy Policy"
                                                               :options="snowOption"
                                                              :class="errors.length > 0 ? 'error' : 'valid'"
                                                              :state="errors.length > 0 ? false : null" />
                                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </form>
                </validation-observer>

                <slot></slot>
                <template #modal-footer="{ cancel, ok }">

                    <b-button @click="closeTermsPrivacyPolicyModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>

                    <b-button @click="updateTermPrivacyPolicy"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="SaveIcon" size="18" />
                        Save
                    </b-button>
                </template>
            </b-modal>

            <b-modal id="projectSettingsModal"
                     ref="modal"
                     title="Update Project Settings"
                     ok-title="Submit"
                     @ok="handleEmailFormOk"
                     centered
                     size="lg">
                <validation-observer ref="projectSettingsRules">
                    <form ref="projectSettingsForm"
                          id="projectSettingsForm"
                          @submit.stop.prevent>
                        <b-row v-if="funnelDef">
                            <b-col lg="12">
                                <b-form-group>

                                    <label>Name<span class="required"> *</span></label>

                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="funnelDefName">
                                        <b-form-input id="funnelDefName"
                                                      name="funnelDefName"
                                                      v-model="funnelDef.Name"
                                                      placeholder="Please Enter Project Name"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12" v-if="UserFunnelFirstStep.TemplateTypeId != 22">
                                <b-form-group>
                                    <div class="demo-inline-spacing">
                                        <label>PDF</label>
                                        <b-form-radio id="PDFFile"
                                                      value="PDFFile"
                                                      v-model="PDFType">
                                            Upload File
                                        </b-form-radio>
                                        <br />
                                        <b-form-radio id="ExternalURL"
                                                      value="ExternalURL"
                                                      v-model="PDFType">
                                            External File URL
                                        </b-form-radio>
                                    </div>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12" v-if="UserFunnelFirstStep.TemplateTypeId != 22">
                                <b-form-group>
                                    <label>Upload PDF</label>
                                    <b-form-file v-model="FunnelPDFFile"
                                                 :disabled="PDFType == 'ExternalURL'"
                                                 ref="file"
                                                 v-on:input="handleFileUpload()"
                                                 :max-size="10246332"
                                                 accept=".pdf"
                                                 placeholder="Choose a file or drop it here..."
                                                 drop-placeholder="Drop file here..." />
                                </b-form-group>
                            </b-col>

                            <b-col lg="12" v-if="UserFunnelFirstStep.TemplateTypeId != 22">
                                <b-form-group>
                                    <label>External Path</label>
                                    <b-form-input id="PDFFileURL"
                                                  :disabled="PDFType == 'PDFFile'"
                                                  name="PDFFileURL"
                                                  v-model="funnelDef.PDFFileURL"
                                                  placeholder="Give PDF URL..." />
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label for="funnelDefPath">Path<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="funnelDefPath">
                                        <b-form-input id="funnelDefPath"
                                                      name="funnelDefPath"
                                                      v-model="funnelDef.Path"
                                                      placeholder="Please Enter Project Path"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12" v-if="UserFunnelFirstStep.TemplateTypeId != 22">
                                <b-form-group>
                                    <div style="display:flex;">
                                        <label style="margin-right:1.2rem;">Enable Double Opt-in?</label>
                                        <b-form-checkbox v-model="funnelDef.IsTwoStepOptin"
                                                         value="true">
                                        </b-form-checkbox>
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12">
                                <b-form-group>
                                    <div style="display:flex;">
                                        <label style="margin-right:1.0rem;">Enable Email Sending?</label>
                                        <b-form-checkbox v-model="funnelDef.IsEmailSending"
                                                         value="true">
                                        </b-form-checkbox>

                                        <feather-icon title="If 'Enable Email Sending' checkbox is checked, automation emails are activated." 
                                                      icon="InfoIcon" size="18"
                                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                      v-b-tooltip.hover.v-success
                                                      class="ml-1 icon-color"
                                                      />
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12">
                                <b-form-group>
                                    <label>Favicon URL</label>
                                    <b-form-input id="FaviconPath"
                                                  name="FaviconPath"
                                                  v-model="funnelDef.FaviconPath"
                                                  placeholder="Give Favicon Path..." />
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label>Header Tracking Code</label>
                                    <b-form-input id="HeadTrackingCode"
                                                  name="HeadTrackingCode"
                                                  v-model="funnelDef.HeadTrackingCode"
                                                  placeholder="Give Head Tracking Code..." />
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label>Footer Tracking Code</label>
                                    <b-form-input id="BodyTrackingCode"
                                                  name="BodyTrackingCode"
                                                  v-model="funnelDef.BodyTrackingCode"
                                                  placeholder="Give Body Tracking Code..." />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </form>
                </validation-observer>

                <slot></slot>
                <template #modal-footer="{ cancel, ok }">

                    <b-button @click="closeProjectSettingsModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>

                    <b-button @click="updateProjectSettings"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="SaveIcon" size="18" />
                        Save
                    </b-button>
                </template>
            </b-modal>

        </b-overlay>
    </section>
    
</template>


<script>
    import BCardCode from "@core/components/b-card-code";
    import {
        BCard,
        BCardHeader,
        BCardBody,
        BCardImg,
        BListGroup,
        BListGroupItem,
        BCardFooter,
        BImg,
        BRow,
        BCol,
        BCardText,
        BCardTitle,
        BCardSubTitle,
        BLink,
        BButton,
        BModal,
        VBModal,
        BFormInput,
        BForm,
        BFormRow,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BFormGroup,
        BTabs,
        BTab,
        BTable,
        BFormSelect,
        BFormCheckbox,
        BFormRadio,
        BFormFile,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormTextarea,
        BOverlay,
        BPagination,
        BAlert,
        BBadge,
        BBreadcrumb,
        BBreadcrumbItem,
        VBTooltip,
    } from "bootstrap-vue";
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import {
        required,
        email,
        confirmed,
        url,
        between,
        alpha,
        integer,
        password,
        min,
        digits,
        alphaDash,
        length,
        numer
    } from "@validations";
    import { $themeConfig } from "@themeConfig";
    import { VueGoodTable } from "vue-good-table";
    import Ripple from "vue-ripple-directive";
    import jwt from "@/auth/jwt/useJwt";
    import { quillEditor } from "vue-quill-editor";
    import clipboard from "v-clipboard";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import axis from "axios";

    import { extend } from 'vee-validate';
    extend('required', {
        message: 'This Field is required',
    });
    extend('email', {
        message: 'Invalid email address',
    });

    extend('min_One', {
        message: 'Must be Greater than or Equal to 1 Positive Number',
        validate: value => {
            var strongRegex = new RegExp("^[1-9][0-9]*$");
            return strongRegex.test(value);
        }
    });

    extend('min_One_decimal', {
        message: 'Must be Greater than or Equal to 1',
        validate: value => {
            var strongRegex = new RegExp("^(?=.*[1-9])[0-9]*[.]?[0-9]{1,2}$");
            return strongRegex.test(value);
        }
    });

    //

    export default {
        metaInfo: {
            title: 'Landingly | Project Detail'
        },
        components: {
            BCardTitle,
            BCardSubTitle,
            BCardCode,
            BCardText,
            BCard,
            BCardHeader,
            BListGroup,
            BListGroupItem,
            BCardFooter,
            BRow,
            BCol,
            BCardBody,
            BCardImg,
            BLink,
            BModal,
            VBModal,
            BButton,
            BFormInput,
            BForm,
            BFormRow,
            BFormValidFeedback,
            BFormInvalidFeedback,
            BFormGroup,
            BTabs,
            BTab,
            BInputGroup,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormTextarea,
            VueGoodTable,
            BTable,
            BFormSelect,
            BFormCheckbox,
            BFormRadio,
            quillEditor,
            ValidationProvider,
            ValidationObserver,
            BOverlay,
            BPagination,
            BAlert,
            BFormFile,
            BBadge,
            BImg,
            BBreadcrumb, BBreadcrumbItem,
        },
        data() {
            return {
                loading: false,
                isloading: true,

                variant: "light",
                opacity: 0.85,
                blur: "2px",

                error: null,
                activeTab: "",

                meta: {
                    pageTitle: 'Project Detail 12',
                    breadcrumb: [
                        {
                            text: 'Projects',
                            to: '/projects'
                        },
                        //{
                        //    text: 'Project Detail',
                        //    active: true
                        //},
                    ],
                },
                breadcrumbActive: true,

                WebAPIURL: $themeConfig.app.apiURL,
                UserDomainURL: $themeConfig.app.UserDomainURL,
                SubDomainURL: null,
                funnelDef: null,
                PaymentMethodId: 0,
                funnelName: null,
                funnelPath: null,
                termConditionsUrl: null,
                privacyUrl: null,
                isProjectCompleted: false,
                UserFunnelSteps: null,
                CheckOutPage: null,
                UserFunnelFirstStep: null,
                showFirstStep: true,
                UserFunnelSecondStep: null,
                loadThankuTemplates: false,
                showSecondStep: false,
                CurrFunnelStepURL: "",
                StepCount: 0,
                FunnelStep: null,
                CurrentStepId: 0,
                CurrUser: JSON.parse(localStorage.getItem("User_Name")),
                userFunnelId: this.$route.params.userfunnelid,
                CurrStepURL: null,

                CustomUserId: null,
                randomStr: null,

                CurrStepID: null,
                urlState: null,
                VariationId: null,
                CustomDomainId: null,
                firstStepURL: "",
                secondStepURL: "",
                firstStepPageURL: "",
                secondStepPageURL: "",
                currIndexSettings: null,
                FunnelPDFFile: null,
                PDFType: "PDFFile",

                StepProducts: null,
                ProductDetail: null,
                selected: null,
                paymentOptions: null,
                Subscription: false,
                IsTrial: false,
                EmailEnabled: false,

                filesDir: $themeConfig.app.UserTemplateFilesDir,
                userFilesDir: $themeConfig.app.UserDomainFilesDir,
                Templates: null,
                SalePageTemplates: null,
                SalePageTemplateId: null,
                SalePageTemplateName: null,
                SalePageVariation: null,
                PaymentMethod: null,
                CheckoutTempId: null,

                autoResponders: null,
                showGetResponse: false,
                showSendLane: false,
                showMailChimp: false,
                showAWeber: false,

                lstAWeberAccount: null,
                lstMailChimpAccount: null,
                lstResponseAccount: null,
                lstSendLaneAccount: null,

                getResponseCode: "",
                getResponseState: "",
                mailChimpCode: "",
                SendLaneSubDomain: "",
                SendLaneAPIKey: "",
                SendLaneHashKey: "",
                aWebwerCode: "",
                aWebwerState: "",

                webFormCode: null,

                StepFunnelEmails: null,
                Delay: 0,
                formaction: "",
                StepFunnelEmailDetail: null,
                TestToEmail: "",

                loginEmail: "",
                loginPassword: "",
                sendAutomationEmails: false,

                StepDoubleOptin: null,
                StepFunnelDoubleOptinDetail: null,
                UserFromEmail: null,
                UserSmtpConfiguration: null,
                hideDraftBtn: false,

                snowOption: {
                    theme: "snow",
                    placeholder: "Please enter it here...",
                    modules: {
                        toolbar: {
                            container: [
                                [{ placeholder: ["[GuestName]", "[HotelName]"] }], // my custom dropdown
                                ["bold", "italic", "underline", "strike"], //??bold, italic, underline, strikethrough
                                ["blockquote"], // reference
                                [{ header: 1 }, { header: 2 }], //??title, the form of the key - value pair; 1, 2 indicates the font size
                                [{ list: "ordered" }, { list: "bullet" }], // List
                                [{ indent: "-1" }, { indent: "+1" }], // Indent
                                [{ direction: "rtl" }], //??text direction
                                [{ size: ["small", false, "large", "huge"] }], // font size
                                [{ header: [1, 2, 3, 4, 5, 6, false] }], //??several levels of title
                                [{ color: [] }, { background: [] }], // font color, font background color
                                [{ font: [] }], //??font
                                [{ align: [] }], //??alignment
                                ["clean"], //??clear font style
                                ["link"],
                                ["image"], //Upload images, upload videos
                            ],
                            handlers: {
                                placeholder: function (value) {
                                    if (value) {
                                        const cursorPosition = this.quill.getSelection().index;
                                        this.quill.insertText(cursorPosition, value);
                                        this.quill.setSelection(cursorPosition + value.length);
                                    }
                                },
                                //image: this.imageHandler
                            },
                        },
                    },
                },
                content: "",

                prodTablefields: [
                    {
                        key: "ProductName",
                        label: "Product Name",
                        sortable: true,
                    },
                    {
                        key: "ProductPrice",
                        label: "Product Price ($)",
                        sortable: true,
                    },
                    {
                        key: "PaymentMethodName",
                        label: "Payment Method",
                        sortable: true,
                    },
                    {
                        key: "IsSandBox",
                        label: "Is SandBox",
                        sortable: true,
                    },
                    {
                        key: "Id",
                        label: "Actions",
                        sortable: false,
                    },
                ],
                prodPageLength: 10,
                prodSearchTerm: "",

                emailTablefields: [
                    {
                        key: "Subject",
                        label: "Subject",
                        sortable: true,
                    },
                    {
                        key: "EmailDeliveryCount",
                        label: "Sent",
                        sortable: true,
                    },
                    {
                        key: "EmailOpenCount",
                        label: "OpenRate",
                        sortable: true,
                    },
                    {
                        key: "EmailClickCount",
                        label: "ClickRate",
                        sortable: true,
                    },
                    {
                        key: "Status",
                        label: "Status",
                        sortable: true,
                    },
                    {
                        key: "Id",
                        label: "Actions",
                        sortable: false,
                    },
                ],
                emailPageLength: 10,
                emailSearchTerm: "",

                perPage: 50,
                pageOptions: [50, 100],
                totalRows: 1,
                currentPage: 1,
                sortBy: "",
                sortDesc: false,
                sortDirection: "asc",
                filter: null,
                filterOn: [],
                infoModal: {
                    id: "info-modal",
                    title: "",
                    content: "",
                },
            };
        },
        directives: {
            "b-modal": VBModal,
            'b-tooltip': VBTooltip,
            Ripple,
        },
        created() {
            var subDomain = jwt.getSubDomain();
            //if (subDomain == null || subDomain == "" || subDomain == 'undefined') {
            //    this.$router.replace('/login')
            //}
            //else {
            if (this.UserDomainURL.includes("localhost")) {
                this.SubDomainURL = this.UserDomainURL;
            }
            else {
                subDomain = subDomain.replace('"', '');
                this.SubDomainURL = this.UserDomainURL.replace('https://', 'https://' + subDomain + '.');
            }
            if (this.$route.name == "getresponse-account") {
                this.getResponseCode = this.$route.query.code;
                this.getResponseState = this.$route.query.state;
                this.verifyGetResponseAccount();
            } else if (this.$route.name == "mailchimp-account") {
                this.mailChimpCode = this.$route.query.code;
                // console.log('route:', this.$route);
                this.verifyMailChimpAccount();
            } else if (this.$route.name == "aweber-account") {
                this.aWebwerCode = this.$route.query.code;
                this.aWebwerState = this.$route.query.state;
                this.verifyAWeberAccount();
            } else if (this.$route.name == "project-detail") {
                this.fetchProjectsData();

            }

            //}
        },
        beforeCreate() {
            
            //else {
            //    if (this.UserDomainURL.includes("localhost")) {
            //        this.SubDomainURL = this.UserDomainURL;
            //    }
            //    else {
            //        subDomain = subDomain.replace('"', '');
            //        this.SubDomainURL = this.UserDomainURL.replace('https://', 'https://' + subDomain + '.');
            //    }
            //    if (this.$route.name == "getresponse-account") {
            //        this.getResponseCode = this.$route.query.code;
            //        this.getResponseState = this.$route.query.state;
            //        this.verifyGetResponseAccount();
            //    } else if (this.$route.name == "mailchimp-account") {
            //        this.mailChimpCode = this.$route.query.code;
            //        console.log('route:', this.$route);
            //        this.verifyMailChimpAccount();
            //    } else if (this.$route.name == "aweber-account") {
            //        this.aWebwerCode = this.$route.query.code;
            //        this.aWebwerState = this.$route.query.state;
            //        this.verifyAWeberAccount();
            //    } else if (this.$route.name == "project-detail") {
            //        this.fetchProjectsData();

            //    }
            //}
        },
        watch: {
            $route: "fetchProjectsData",
        },
        computed: {

        },

        mounted: function (e) {
            if (this.StepFunnelEmails != null) {
                this.totalRows = this.StepFunnelEmails.length;
            } else {
                this.totalRows = 0;
            }
            const viewportMeta = document.createElement('meta');
            viewportMeta.name = 'viewport';
            viewportMeta.content = 'width=device-width, initial-scale=1';
            document.head.appendChild(viewportMeta);
        },

        methods: {
            imageHandler() {
                this.selectLocalImage();
                // Implement your image upload logic here
                // You can open a file picker dialog, upload the image, and insert it into the editor
            },

            selectLocalImage() {
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.click();

                // Listen upload local image and save to server
                input.onchange = () => {
                    const file = input.files[0];

                    // file type is only image.
                    if (/^image\//.test(file.type)) {
                        console.warn('images upload.');
                        //this.saveToServer(file);
                    } else {
                        console.warn('You could only upload images.');
                    }
                };
            },

            //saveToServer(file: File) {
            //    const fd = new FormData();
            //    fd.append('image', file);

            //    const xhr = new XMLHttpRequest();
            //    xhr.open('POST', 'http://localhost:3000/upload/image', true);
            //    xhr.onload = () => {
            //        if (xhr.status === 200) {
            //            // this is callback data: url
            //            const url = JSON.parse(xhr.responseText).data;
            //            this.insertToEditor(url);
            //        }
            //    };
            //    xhr.send(fd);
            //},

            //insertToEditor(url: string) {
            //    // push image url to rich editor.
            //    //const range = editor.getSelection();
            //    //editor.insertEmbed(range.index, 'image', `http://localhost:9000${url}`);
            //},

            updateTemplates() {

                this.showFirstStep = false;
                this.showSecondStep = true;

                var self = this;
                this.loading = true;
                setTimeout(function () {
                    // console.log(self.$refs.refSecondStep);
                    self.$refs.refSecondStep.$el.click();

                }, 500);

            },

            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`;
                this.infoModal.content = JSON.stringify(item, null, 2);
                this.$root.$emit("bv::show::modal", this.infoModal.id, button);
            },

            resetInfoModal() {
                this.infoModal.title = "";
                this.infoModal.content = "";
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            checkFormValidity() {
                const valid = this.$refs.form.checkValidity();
                this.urlState = valid;
                return valid;
            },

            resetModal() {
                this.CurrStepURL = "";
                this.urlState = null;
            },
            resetTestEmailModal() {
                this.TestToEmail = null;
            },

            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.handleSubmit();
            },

            handleFormOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.saveProduct();
            },

            closeProductModal() {
                this.$bvModal.hide("addEditProductsModal");
            },

            handleEmailFormOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.saveEmail();
            },

            HandleTermsPrivacyPolicyOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                //this.saveEmail();
            },

            handleDoubleOptinForm(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.saveDoubleOptinEmail();
            },

            handleSubmit: async function () {
                const access_token = await jwt.getToken();
                this.$refs.CurrStepURLFormRules.validate().then((success) => {
                    if (success) {
                        const self = this;

                        var axios = require("axios");
                        var qs = require("qs");

                        var data = qs.stringify({
                            StepId: this.CurrStepID,
                            StepUrl: this.CurrStepURL,
                            CustomDomainId: this.CustomDomainId,
                            VariationId: this.VariationId,
                            StepName: "",
                        });
                        var config = {
                            method: "post",
                            url:
                                "" +
                                this.WebAPIURL +
                                "/api/projects/UpdateFunnelStepSettings?" +
                                data,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };

                        axios(config)
                            .then(function (response) {
                                //console.log(response.data);
                                self.$nextTick(() => {
                                    self.$bvModal.hide("stepSettingsModal");
                                });
                                if (self.currIndexSettings == 0) {
                                    self.firstStepPageURL = self.firstStepPageURL.replace(
                                        self.firstStepURL,
                                        self.CurrStepURL
                                    );
                                    self.firstStepURL = self.CurrStepURL;
                                } else {
                                    self.secondStepPageURL = self.secondStepPageURL.replace(
                                        self.secondStepURL,
                                        self.CurrStepURL
                                    );
                                    self.secondStepURL = self.CurrStepURL;
                                }
                            })
                            .catch(function (error) {
                                // console.log(error);
                            });
                    }
                })
            },

            OpenSettingsModal: function (e) {
                const stepurl = e.currentTarget.getAttribute("data-stepurl");
                const stepid = e.currentTarget.getAttribute("data-stepid");
                const variationid = e.currentTarget.getAttribute("data-variationid");
                const currIndex = e.currentTarget.getAttribute("data-currIndex"); //data-currIndex
                this.currIndexSettings = currIndex;

                if (variationid > 0) {
                    this.CustomDomainId = e.currentTarget.getAttribute(
                        "data-customdomainvariationid"
                    );
                } else {
                    this.CustomDomainId = e.currentTarget.getAttribute(
                        "data-customdomainid"
                    );
                }
                this.VariationId = variationid;
                this.CurrStepURL = stepurl;
                this.CurrStepID = stepid;
            },

            LoadSalePageTemplates: async function (e) {
                this.loading = true;
                var self = this;

                const isloaded = e.currentTarget.getAttribute("data-isloaded");
                const funnelId = this.userFunnelId;
                const Order = e.currentTarget.getAttribute("data-order");
                const TemplateTypeId = e.currentTarget.getAttribute("data-temptypeid");
                const TemplateId = null;
                const UserStepId = e.currentTarget.getAttribute("data-userstepid");

                const access_token = await jwt.getToken();
                var axios = require("axios");
                var qs = require("qs");

                var data = qs.stringify({
                    TemplateTypeId: TemplateTypeId,
                    Order: Order,
                    TemplateId: TemplateId,
                    funnelId: funnelId,
                    UserStepId: UserStepId,
                });
                var config = {
                    method: "get",
                    url: "" + this.WebAPIURL + "/api/projects/GetTemplates?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        self.SalePageTemplates = response.data.Templates;
                        self.loading = false;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            LoadTemplates: async function (e) {
                this.loading = true;
                var self = this;

                const isloaded = e.currentTarget.getAttribute("data-isloaded");
                const funnelId = this.userFunnelId;
                const Order = e.currentTarget.getAttribute("data-order");
                const TemplateTypeId = e.currentTarget.getAttribute("data-temptypeid");
                const TemplateId = null;
                const UserStepId = e.currentTarget.getAttribute("data-userstepid");

                console.log("TemplateTypeId : " + TemplateTypeId);

                const access_token = await jwt.getToken();
                var axios = require("axios");
                var qs = require("qs");

                var data = qs.stringify({
                    TemplateTypeId: TemplateTypeId,
                    Order: Order,
                    TemplateId: TemplateId,
                    funnelId: funnelId,
                    UserStepId: UserStepId,
                });
                var config = {
                    method: "get",
                    url: "" + this.WebAPIURL + "/api/projects/GetTemplates?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        //console.log(JSON.stringify(response.data));
                        self.Templates = response.data.Templates;
                        self.loading = false;
                        // this.CurrStepID = UserStepId;
                        if (TemplateTypeId == 22) {
                            this.getStepProducts();
                        }
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            UseThisTemplate: async function (e) {
                this.error = null;
                var self = this;
                this.loading = true;

                const tabindex = e.currentTarget.getAttribute("data-index");
                const userstepid = e.currentTarget.getAttribute("data-userstepid");
                const parentfolder = e.currentTarget.getAttribute("data-parentfolder");
                const mainfolder = e.currentTarget.getAttribute("data-mainfolder");
                const childfolder = e.currentTarget.getAttribute("data-childfolder");
                const templatetypeid = e.currentTarget.getAttribute(
                    "data-templatetypeid"
                );
                const funnelid = e.currentTarget.getAttribute("data-funnelid");
                const templatefilename = e.currentTarget.getAttribute(
                    "data-templatefilename"
                );
                const TemplateId = e.currentTarget.getAttribute("data-value");
                const currenttabid = e.currentTarget.getAttribute("data-currenttabid");
                const TemplateFunnelId = e.currentTarget.getAttribute(
                    "data-TemplateFunnelId"
                );

                const access_token = await jwt.getToken();
                var axios = require("axios");
                var qs = require("qs");

                var data = qs.stringify({
                    CurrentStepId: currenttabid,
                    UserStepId: userstepid,
                    TemplateId: TemplateId,
                    FunnelId: funnelid,
                    StepName: "",
                    StepUrl: "",
                    TemplateFileName: templatefilename,
                    TemplateTypeId: templatetypeid,
                    IsUserTemplate: false,
                    TemplateFunnelId: TemplateFunnelId,
                    CustomDomainId: null,
                    SectionId: null,
                    DelayDays: null,
                    MainFoder: mainfolder,
                    ParentFolder: parentfolder,
                    ChildFolder: childfolder,
                    VariationCount: 0,
                    Order: "1",
                    TemplateName: "",
                    IsBasicUser: false,
                });
                var config = {
                    method: "post",
                    url:
                        "" +
                        this.WebAPIURL +
                        "/api/projects/UpdateTemplateAgainstStep?" +
                        data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {

                        // if (response.data.length > 1) {
                        self.UserFunnelSteps = response.data;
                        self.UserFunnelFirstStep = response.data[0];
                        self.UserFunnelSecondStep = response.data[1];

                        if (tabindex != null && tabindex == 0) {
                            self.showFirstStep = false;
                            self.showSecondStep = true;
                            self.$refs.refSecondStep.$el.click();
                        }
                        else if (tabindex != null && tabindex == 1) {
                            self.showFirstStep = true;
                            self.showSecondStep = false;
                            self.$refs.refFirstStep.$el.click();
                        }
                        self.Templates = null;
                        //}
                        //else {
                        //    self.CheckOutPage = response.data[0];
                        //    self.SalePageTemplates = null;
                        //}
                        self.loading = false;

                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            rndStr(len) {
                let text = ""
                let chars = "abcdefghijklmnopqrstuvwxyz0123456789"

                for (let i = 0; i < len; i++) {
                    text += chars.charAt(Math.floor(Math.random() * chars.length))
                }

                return text
            },

            fetchProjectsData: async function () {
                this.error = this.projects = null;
                var self = this;
                this.loading = true;
                var axios = require("axios");
                const access_token = await jwt.getToken();
                /*alert(access_token)*/
                var headers = {
                    Authorization: "bearer " + access_token,
                };

                axios
                    .get(
                        "" +
                        this.WebAPIURL +
                        "/api/projects/ProjectDetail?UserFunnelId=" +
                        this.userFunnelId +
                        "&stepId=" + self.CurrentStepId + "",
                        { headers }
                    )
                    .then(function (response) {
                        self.CurrentStepId = 0;
                        self.UserFunnelSteps = response.data.UserFunnelSteps;
                        self.UserFunnelFirstStep = response.data.UserFunnelSteps[0];
                        self.UserFunnelSecondStep = response.data.UserFunnelSteps[1];
                        // self.CheckOutPage = response.data.CheckOutPage;

                        self.StepFunnelEmails = response.data.FunnelEmails;
                        self.CustomUserId = response.data.CustomUserId;
                        self.randomStr = self.rndStr(10);

                        self.StepDoubleOptin = response.data.DoubleOptinEmail;
                        self.UserFromEmail = response.data.UserFromEmail;
                        self.UserSmtpConfiguration = response.data.lstFunnelUserSmtpConfiguration;
                        //console.log("self.randomStr::" + self.randomStr)
                        // console.log(response.data.Templates);
                        self.Templates = response.data.Templates;
                        self.autoResponders = response.data.AutoResponderList;
                        //console.log("autoResponders:: " + self.autoResponders.length);
                        self.loading = false;

                        self.webFormCode = response.data.WebFormCode;

                        self.funnelDef = response.data.Funnel;
                        // console.log(response.data.Funnel);
                        self.funnelName = response.data.FunnelName;
                        self.funnelPath = response.data.FunnelPath;

                        self.termConditionsUrl = response.data.FunnelPath + "/terms";
                        self.privacyUrl = response.data.FunnelPath + "/privacy";

                        var valObj = response.data.UserFunnelSteps.filter(function (elem) {
                            if (elem.UserFunnelStepVariationId > 0)
                                return elem;
                        });

                        if (valObj.length > 0) {
                            self.isProjectCompleted = true;
                        } else {
                            self.isProjectCompleted = false;
                        }

                        const funnelStep = response.data.UserFunnelSteps[0];

                        self.firstStepURL = response.data.UserFunnelSteps[0].FunnelStepURL;
                        self.secondStepURL = response.data.UserFunnelSteps[1].FunnelStepURL;

                        self.firstStepPageURL =
                            response.data.UserFunnelSteps[0].FunnelStepPageURL;
                        self.secondStepPageURL =
                            response.data.UserFunnelSteps[1].FunnelStepPageURL;

                        self.lstAWeberAccount = response.data.lstAWeberAccount;
                        self.lstMailChimpAccount = response.data.lstMailChimpAccount;
                        self.lstResponseAccount = response.data.lstResponseAccount;
                        self.lstSendLaneAccount = response.data.lstSendLaneAccount;

                        if (
                            response.data.UserFunnelSteps[0].AutoResponderID ==
                            $themeConfig.app.GetResponse
                        ) {
                            self.showGetResponse = true;
                            self.showSendLane = false;
                            self.showMailChimp = false;
                            self.showAWeber = false;
                        }
                        if (
                            response.data.UserFunnelSteps[0].AutoResponderID ==
                            $themeConfig.app.SendLane
                        ) {
                            self.showGetResponse = false;
                            self.showSendLane = true;
                            self.showMailChimp = false;
                            self.showAWeber = false;
                        }
                        if (
                            response.data.UserFunnelSteps[0].AutoResponderID ==
                            $themeConfig.app.MailChimp
                        ) {
                            self.showGetResponse = false;
                            self.showSendLane = false;
                            self.showMailChimp = true;
                            self.showAWeber = false;
                        }
                        if (
                            response.data.UserFunnelSteps[0].AutoResponderID ==
                            $themeConfig.app.AWeber
                        ) {
                            self.showGetResponse = false;
                            self.showSendLane = false;
                            self.showMailChimp = false;
                            self.showAWeber = true;
                        }

                        self.CurrStepID = funnelStep.Id;
                        if (funnelStep.TemplateTypeId == 22) {
                            self.getStepProducts();

                        }

                        if (self.UserFunnelFirstStep != null && self.UserFunnelFirstStep.UserFunnelStepVariationId > 0 && (self.UserFunnelSecondStep.UserFunnelStepVariationId == null || self.UserFunnelSecondStep.UserFunnelStepVariationId < 0)) {
                            self.loadThankuTemplates = true;

                            self.updateTemplates();
                        }
                        else {
                            self.showFirstStep = true;
                            self.showSecondStep = false;
                            // self.$refs.refFirstStep.$el.click();
                        }
                        //const placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));

                        //placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);

                        //document.querySelector('.ql-placeholder .ql-picker-label').innerHTML
                        //    = 'Insert placeholder' + document.querySelector('.ql-placeholder .ql-picker-label').innerHTML;
                    })
                    .catch(function (error) {
                        self.error = error;
                        self.loading = false;
                    });
            },

            getStepProducts: async function () {
                this.error = null;
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    UserStepId: this.CurrStepID,
                });
                var config = {
                    method: "get",
                    url:
                        "" + this.WebAPIURL + "/api/projects/GetFunnelStepProducts?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        //console.log(response.data);
                        if (response.data != null && response.data.length > 0) {
                            self.StepProducts = response.data;
                            self.items = response.data;
                        } else {
                            self.StepProducts = null;
                        }
                        self.loading = false;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            addEditProduct: async function (e) {
                const prodId = e.currentTarget.getAttribute("data-id");
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    FunnelStepId: this.CurrStepID,
                    UserFunnelId: this.userFunnelId,
                    Id: prodId,
                });
                var config = {
                    method: "get",
                    url: "" + this.WebAPIURL + "/api/projects/CreateProduct?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        self.ProductDetail = response.data;

                        var PaymentMethodId = null;//self.ProductDetail.FFProduct.PaymentMethodId;
                        if (self.ProductDetail.FFProduct != null) {
                            PaymentMethodId = self.ProductDetail.FFProduct.PaymentMethodId;
                            self.PaymentMethod = self.ProductDetail.FFProduct.PaymentMethodCode;
                            self.CheckoutTempId = self.ProductDetail.FFProduct.TemplateId;

                            self.SalePageTemplateName = self.ProductDetail.FFProduct.CheckOutPage;
                            self.SalePageVariation = self.ProductDetail.FFProduct.CheckOutVariation;
                            self.SalePageTemplateId = self.ProductDetail.FFProduct.TemplateId;

                            // self.LoadCheckoutPageTemplates();
                        }
                        self.PaymentMethodId = PaymentMethodId;

                        if (response.data.FFProduct.ProductIsRecurring == true) {
                            //Currently commented to hide subscription
                            //self.Subscription = true;
                        } else {
                            self.Subscription = false;
                        }

                        if (response.data.FFProduct.ProductIsTrial == false) {
                            self.IsTrial = false;
                        } else {
                            self.IsTrial = true;
                        }

                        if (response.data.FFProduct.IsEmailEnabled == true) {
                            self.EmailEnabled = true;
                        } else {
                            self.EmailEnabled = false;
                        }
                        //self.LoadSalePageTemplates();
                        self.loading = false;
                        self.$nextTick(() => {
                            self.$bvModal.show("addEditProductsModal");
                        });


                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            SubscriptionDiv: function (e) {
                if (this.Subscription == true) {
                    this.Subscription = false;
                } else {
                    this.Subscription = true;
                }
            },

            TrialDiv() {
                if (this.IsTrial == true) {
                    this.IsTrial = false;
                } else {
                    this.IsTrial = true;
                }
            },

            fnEmailEnabled(arg) {
                if (arg == "true") {
                    this.EmailEnabled = true;
                } else {
                    this.EmailEnabled = false;
                }
            },

            saveProduct: async function () {
                const access_token = await jwt.getToken();
                this.$refs.productRules.validate().then((success) => {
                    if (success) {
                        this.loading = true;
                        const formData = new FormData(this.$refs["CreateProductFormFunnel"]);
                        const data = {};
                        for (let [key, val] of formData.entries()) {
                            Object.assign(data, { [key]: val });
                        }
                        var PaymentProcessorId;
                        if (data.PaymentMethodId == 1) {

                            PaymentProcessorId = data.PaypalAccounts;
                        }
                        else if (data.PaymentMethodId == 2) {
                            PaymentProcessorId = data.StripeAccounts;

                        }
                        else if (data.PaymentMethodId == 3) {
                            PaymentProcessorId = data.AuthorizeNetAccounts;
                        }
                        else if (data.PaymentMethodId == 4) {
                            PaymentProcessorId = data.BrainTreeAccounts;
                        }

                        var self = this;
                        var qs = require("qs");
                        var CustomProductId = "0";
                        if (this.ProductDetail.FFProduct.Id > 0) {
                            CustomProductId = this.ProductDetail.FFProduct.CustomProductID;
                        }

                        const originalEmailText = this.ProductDetail.FFProduct.FulfillmentEmailBody;
                        const textEncoder = new TextEncoder('iso-8859-1');
                        const encodedEmailText = textEncoder.encode(originalEmailText);

                        let formdata = new FormData();
                        formdata.append('CustomProductID', CustomProductId);
                        formdata.append('Id', data.Id);
                        formdata.append('IpnUrl', data.IpnUrl);
                        formdata.append('IsEmailEnabled', data.IsEmailEnabled);
                        formdata.append('PaymentMethodId', data.PaymentMethodId);
                        formdata.append('PaymentProcessorId', PaymentProcessorId);
                        formdata.append('ProductArchived', data.ProductArchived);
                        formdata.append('ProductIsRecurring', data.ProductIsRecurring);
                        formdata.append('ProductIsTrial', data.ProductIsTrial);
                        formdata.append('ProductName', data.ProductName);
                        formdata.append('ProductNumberOfPayments', data.ProductNumberOfPayments);
                        formdata.append('ProductPaymentFrequency', data.ProductPaymentFrequency);
                        formdata.append('ProductPaymentPeriod', data.ProductPaymentPeriod);
                        formdata.append('ProductPrice', data.ProductPrice);
                        formdata.append('ProductTrialPeriod', data.ProductTrialPeriod);
                        formdata.append('ProductTrialPeriodFrequency', data.ProductTrialPeriodFrequency);
                        formdata.append('ProductTrialPrice', data.ProductTrialPrice);
                        formdata.append('SmtpConfigurationId', data.SmtpConfigurationId);
                        formdata.append('Subject', data.Subject);
                        formdata.append('UserFunnelId', data.UserFunnelId);
                        formdata.append('UserTabNo', data.UserTabNo);
                        formdata.append('IsSandBox', this.ProductDetail.FFProduct.IsSandBox);
                        formdata.append('FulfillmentEmailBody', window.btoa(String.fromCharCode(...encodedEmailText)));
                        formdata.append('PlanId', this.ProductDetail.FFProduct.PlanId);
                        formdata.append('SalePageTemplateName', this.SalePageTemplateName);
                        formdata.append('SalePageVariation', this.SalePageVariation);
                        formdata.append('SalePageTemplateId', this.SalePageTemplateId);

                        var axios = require("axios");

                        //var config = {
                        //    method: "post",
                        //    url:
                        //        "" + this.WebAPIURL + "/api/projects/AddEditProduct?" + abdData,
                        //    headers: {
                        //        Authorization: "bearer " + access_token,
                        //    },
                        //    data: abdData,
                        //};

                        // axios(config)
                        axios
                            .post("" + this.WebAPIURL + "/api/projects/AddEditProduct",
                                formdata, {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            })
                            .then(function (response) {
                                //console.log(response.data);
                                self.$nextTick(() => {
                                    self.$bvModal.hide("addEditProductsModal");
                                });
                                self.getStepProducts();
                                self.loading = false;
                            })
                            .catch(function (error) {
                                // console.log(error);
                                self.loading = false;
                            });
                    }
                });
            },

            removeProduct: async function (e) {
                const prodId = e.currentTarget.getAttribute("data-id");
                var self = this;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    StepId: this.CurrStepID,
                    ProductId: prodId,
                });
                this.$swal({
                    title: "Delete Product",
                    text: "Are you sure?",
                    //icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Delete",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        this.loading = true;
                        var config = {
                            method: "post",
                            url:
                                "" + this.WebAPIURL + "/api/projects/RemoveFunnelProduct?" + data,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };

                        axios(config)
                            .then(function (response) {
                                //console.log(response.data);
                                self.getStepProducts();
                                self.loading = false;
                            })
                            .catch(function (error) {
                                // console.log(error);
                                self.loading = false;
                            });
                    }
                });
            },

            cloneFunnelEmail: async function (e) {
                const emailId = e.currentTarget.getAttribute("data-id");
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    UserFunnelId: this.userFunnelId,
                    Id: emailId,
                });

                console.log(emailId);
                console.log(this.userFunnelId);

                var config = {
                    method: "post",
                    url:
                        "" + this.WebAPIURL + "/api/projects/CloneFunnelEmail?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        self.getStepEmails();
                        self.loading = false;

                    })
                    .catch(function (error) {
                        self.loading = false;
                    });

            },

            addEditEmail: async function (e) {
                const emailId = e.currentTarget.getAttribute("data-id");
                
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    UserFunnelId: this.userFunnelId,
                    Id: emailId,
                });

                var config = {
                    method: "get",
                    url:
                        "" + this.WebAPIURL + "/api/projects/GetFunnelEmailDetails?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        // console.log(response.data);
                        self.StepFunnelEmailDetail = response.data;
                        if (self.StepFunnelEmailDetail.FunnelEmail.DelayDays < 9) {
                            self.Delay = self.StepFunnelEmailDetail.FunnelEmail.DelayDays;
                        } else {
                            self.Delay = 8;
                        }
                        if (self.StepFunnelEmailDetail.FunnelEmail.IsScheduled !== undefined) {
                            self.hideDraftBtn = self.StepFunnelEmailDetail.FunnelEmail.IsScheduled;
                        }
                        self.loading = false;

                        self.$nextTick(() => {
                            self.$bvModal.show("addEditEmailModal");
                        });
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            addEditDoubleOptin: async function (e) {
                const emailId = e.currentTarget.getAttribute("data-id");
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    UserFunnelId: this.userFunnelId,
                    Id: emailId,
                });

                var config = {
                    method: "get",
                    url:
                        "" + this.WebAPIURL + "/api/projects/GetDoubleOptinEmails?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        console.log(response.data);
                        self.StepFunnelDoubleOptinDetail = response.data;
                        self.loading = false;
                        self.$nextTick(() => {
                            self.$bvModal.show("addEditDoubleOptinEmailModal");
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.loading = false;
                    });
            },

            onDelayChange(event) {
                console.log(event.target.value);
                this.StepFunnelEmailDetail.FunnelEmail.DelayDays = event.target.value;
                this.StepFunnelEmailDetail.FunnelEmail.DelayHours = 0;
            },

            onDayChange(event) {
                if (event.target.value < 9) {
                    this.Delay = event.target.value;
                } else {
                    this.Delay = 8;
                }
            },

            closeEmailModal() {
                this.$bvModal.hide("addEditEmailModal");
            },



            saveDraftEmail() {
                this.formaction = "SAVE";
                this.StepFunnelEmailDetail.FunnelEmail.IsScheduled = false;
                this.saveEmail();
            },

            scheduleEmail() {
                this.formaction = "SAVESCHEDULE";
                this.StepFunnelEmailDetail.FunnelEmail.IsScheduled = true;
                this.saveEmail();
            },

            saveOptinEmail() {
                this.formaction = "SAVE";
                this.saveDoubleOptinEmail();
            },

            saveDoubleOptinEmail: async function () {
                const access_token = await jwt.getToken();
                this.$refs.emailRules.validate().then((success) => {
                    if (success) {
                        this.loading = true;
                        const formData = new FormData(this.$refs["CreateDoubleOptinEmail"]);
                        const data = {};
                        for (let [key, val] of formData.entries()) {
                            Object.assign(data, { [key]: val });

                        }
                        var self = this;

                        const originalEmailText = this.StepDoubleOptin.EmailText;
                        const textEncoder = new TextEncoder('iso-8859-1');
                        const encodedEmailText = textEncoder.encode(originalEmailText);

                        let formdata = new FormData();
                        formdata.append('EmailId', data.FunnelEmailId);
                        formdata.append('FromName', data.FromName);
                        formdata.append('Subject', data.Subject);
                        formdata.append('EmailText', window.btoa(String.fromCharCode(...encodedEmailText)));
                        formdata.append('ReplyToEmail', data.ReplyToEmail);
                        formdata.append('EmailProjectId', data.EmailProjectId);
                        formdata.append('EmailUserStepId', data.EmailUserStepId);
                        formdata.append('SmtpConfiguration', data.ConfigurationId);


                        var axios = require("axios");

                        axios
                            .post("" + this.WebAPIURL + "/api/projects/AddDoubleOptinEmail",
                                formdata, {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            })
                            .then(function (response) {
                                self.$swal('Success',
                                    '',
                                    'success'
                                );
                                //self.$nextTick(() => {
                                //    self.$bvModal.hide("addEditDoubleOptinEmailModal");
                                //});
                                //self.getDoubleOptinEmail();
                                self.loading = false;
                            })
                            .catch(function (error) {
                                // console.log(error);
                                self.$swal('Error',
                                    'Something went wrong Please try again!',
                                    'error'
                                );
                                // console.log(error);
                                self.loading = false;
                            });
                    }
                })
            },

            getDoubleOptinEmail: async function () {
                this.error = null;
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    UserFunnelId: this.userFunnelId,
                });

                var config = {
                    method: "get",
                    url: "" + this.WebAPIURL + "/api/projects/GetDoubleOptinEmails?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        //console.log(response.data);
                        if (response.data != null && response.data.length > 0) {
                            self.StepDoubleOptin = response.data;
                        } else {
                            self.StepDoubleOptin = null;
                        }
                        self.loading = false;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });

            },

            saveEmail: async function () {
                const access_token = await jwt.getToken();
                this.$refs.emailRules.validate().then((success) => {
                    if (success) {
                        this.loading = true;
                        const formData = new FormData(this.$refs["CreateEmailFormFunnel"]);
                        const data = {};
                        for (let [key, val] of formData.entries()) {
                            Object.assign(data, { [key]: val });
                        }
                        var self = this;

                        const originalEmailText = this.StepFunnelEmailDetail.FunnelEmail.EmailText;
                        const textEncoder = new TextEncoder('iso-8859-1');
                        const encodedEmailText = textEncoder.encode(originalEmailText);

                        let formdata = new FormData();
                        formdata.append('FunnelEmailId', data.FunnelEmailId);
                        formdata.append('formaction', this.formaction);
                        formdata.append('FromName', data.FromName);
                        formdata.append('Subject', data.Subject);
                        formdata.append('EmailText', window.btoa(String.fromCharCode(...encodedEmailText)));
                        formdata.append('ReplyToEmail', data.ReplyToEmail);
                        formdata.append('CamSpam', data.CamSpam);
                        formdata.append('EmailProjectId', data.EmailProjectId);

                        formdata.append('EmailUserStepId', data.EmailUserStepId);
                        formdata.append('SmtpConfiguration', data.ConfigurationId);
                        formdata.append('DelayDays', data.DelayDays);
                        formdata.append('DelayHours', data.DelayHours);
                        formdata.append('IsScheduled', data.IsScheduled);
                        console.log("IsScheduled", data.IsScheduled);
                        var axios = require("axios");
                        axios
                            .post("" + this.WebAPIURL + "/api/projects/AddFunnelEmail",
                                formdata, {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            })
                            .then(function (response) {

                                self.$nextTick(() => {
                                    self.$bvModal.hide("addEditEmailModal");
                                });
                                self.getStepEmails();
                                self.loading = false;
                            })
                            .catch(function (error) {
                                // console.log(error);
                                self.loading = false;
                            });
                    }
                });
            },

            deleteStepEmails: async function (e) {
                const emailId = e.currentTarget.getAttribute("data-id");
                const IsSchedule = e.currentTarget.getAttribute("data-IsScheduled");
                var msg = "";
                if (IsSchedule) {
                    msg = "Are you sure? <br/> All existing scheduled emails against this email would be deleted.";
                }
                else {
                    msg = "Are you sure?";
                }
                var self = this;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    FunnelEmailId: emailId,
                });
                this.$swal({
                    title: "Delete Email",
                    html: msg,
                    //icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Delete",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        self.loading = true;
                        var config = {
                            method: "post",
                            url:
                                "" + self.WebAPIURL + "/api/projects/DeleteFunnelEmail?" + data,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };
                        axios(config)
                            .then(function (response) {
                                self.$swal({
                                    icon: "success",
                                    title: "Deleted!",
                                    //text: "Your email has been deleted.",
                                    customClass: {
                                        confirmButton: "btn btn-success",
                                    },
                                });
                                self.getStepEmails();
                                self.loading = false;
                            })
                            .catch(function (error) {
                                // console.log(error);
                                self.loading = false;
                            });
                    }
                });
            },

            getStepEmails: async function () {
                this.error = null;
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    UserFunnelId: this.userFunnelId,
                });
                var config = {
                    method: "get",
                    url: "" + this.WebAPIURL + "/api/projects/GetFunnelEmails?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        //console.log(response.data);
                        if (response.data != null && response.data.length > 0) {
                            self.StepFunnelEmails = response.data;
                        } else {
                            self.StepFunnelEmails = null;
                        }
                        self.loading = false;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            openSendTestEmailModal: async function () {
                var self = this;
                this.$refs.emailRules.validate().then((success) => {
                    if (success) {
                        self.$nextTick(() => {
                            self.$bvModal.show("sendTestEmailModal");
                        });

                    }
                })

            },
            closeTestEmailModal() {
                this.$bvModal.hide("sendTestEmailModal");
            },

            sendTestEmail: async function () {
                const access_token = await jwt.getToken();
                this.$refs.testEmailRules.validate().then((success) => {
                    if (success) {
                        this.loading = true;
                        var self = this;
                        var toEmail = this.TestToEmail;

                        const originalEmailText = this.StepFunnelEmailDetail.FunnelEmail.EmailText;
                        const textEncoder = new TextEncoder('iso-8859-1');
                        const encodedEmailText = textEncoder.encode(originalEmailText);

                        let formdata = new FormData();
                        formdata.append('FromName', this.StepFunnelEmailDetail.FunnelEmail.FromName);
                        formdata.append('Subject', this.StepFunnelEmailDetail.FunnelEmail.Subject);
                        formdata.append('EmailText', window.btoa(String.fromCharCode(...encodedEmailText)));
                        formdata.append('SendToEmail', toEmail);
                        formdata.append('ReplyToEmail', this.StepFunnelEmailDetail.FunnelEmail.ReplyToEmail);
                        formdata.append('SmtpConfigurationId', this.StepFunnelEmailDetail.FunnelEmail.ConfigurationId);
                        formdata.append('CamSpam', this.StepFunnelEmailDetail.FunnelEmail.CamSpam);

                        var axios = require("axios");
                        axios
                            .post("" + this.WebAPIURL + "/api/projects/SendTestingEmail",
                                formdata, {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            })
                            .then(function (response) {
                                self.$nextTick(() => {
                                    self.$bvModal.hide("sendTestEmailModal");
                                });
                                self.$swal({
                                    icon: "success",
                                    title: "Email Sent!",
                                    //text: "Your test email has been sent successfully.",
                                    customClass: {
                                        confirmButton: "btn btn-success",
                                    },
                                });
                                self.loading = false;
                            })
                            .catch(function (error) {
                                // console.log(error);
                                self.$swal({
                                    icon: "error",
                                    title: "Email Sent Failure!",
                                    text: "Something went wrong please try again latter.",
                                    customClass: {
                                        confirmButton: "btn btn-success",
                                    },
                                });
                                self.loading = false;
                            });
                    }
                });
            },

            onAutoRespChange(event) {
                if (event.target.value == $themeConfig.app.GetResponse) {
                    this.showGetResponse = true;
                    this.showSendLane = false;
                    this.showMailChimp = false;
                    this.showAWeber = false;
                }
                else if (event.target.value == $themeConfig.app.SendLane) {
                    this.showGetResponse = false;
                    this.showSendLane = true;
                    this.showMailChimp = false;
                    this.showAWeber = false;
                }
                else if (event.target.value == $themeConfig.app.MailChimp) {
                    this.showGetResponse = false;
                    this.showSendLane = false;
                    this.showMailChimp = true;
                    this.showAWeber = false;
                }
                else if (event.target.value == $themeConfig.app.AWeber) {
                    this.showGetResponse = false;
                    this.showSendLane = false;
                    this.showMailChimp = false;
                    this.showAWeber = true;
                }
                else {
                    this.showGetResponse = false;
                    this.showSendLane = false;
                    this.showMailChimp = false;
                    this.showAWeber = false;
                }
            },

            loadGetResponseAccount: async function (e) {
                const action = e.currentTarget.getAttribute("data-action");
                this.error = null;
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    UserFunnelId: this.userFunnelId,
                    AutoResponderId: this.UserFunnelSteps[0].AutoResponderID,
                    StepId: this.CurrStepID,
                    ActionName: action,
                });
                var config = {
                    method: "get",
                    url:
                        "" +
                        this.WebAPIURL +
                        "/api/AutoResponders/VerifyGetResponseAccountOath2?" +
                        data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        //self.loading = false;
                        localStorage.setItem("UserFunnelId", self.userFunnelId);
                        console.log(response.data);
                        window.location.href = response.data;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            verifyGetResponseAccount: async function () {
                this.error = null;
                var self = this;
                this.loading = true;

                if (!this.userFunnelId) {
                    this.userFunnelId = localStorage.getItem("UserFunnelId");
                }
                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    code: this.getResponseCode,
                    state: this.getResponseState,
                    UserFunnelId: this.userFunnelId,
                });
                var config = {
                    method: "post",
                    url:
                        "" +
                        this.WebAPIURL +
                        "/api/AutoResponders/RedirectURLGetResponseAccount?" +
                        data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        //console.log(response.data);
                        self.$router.push(
                            "/projectdetail/" + localStorage.getItem("UserFunnelId")
                        );
                        self.activeTab = "AutoResponder";
                        self.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.loading = false;
                    });
            },

            loadMailChimpAccount: async function (e) {
                const action = e.currentTarget.getAttribute("data-action");
                this.error = null;
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    UserFunnelId: this.userFunnelId,
                    AutoResponderId: this.UserFunnelSteps[0].AutoResponderID,
                    StepId: this.CurrStepID,
                    ActionName: action,
                });
                var config = {
                    method: "get",
                    url:
                        "" +
                        this.WebAPIURL +
                        "/api/AutoResponders/VerifyMailChimpAccountOath2?" +
                        data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        //console.log(response.data);
                        //self.loading = false;
                        localStorage.setItem("UserFunnelId", self.userFunnelId);
                        window.location.href = response.data;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            verifyMailChimpAccount: async function () {
                this.error = null;
                var self = this;
                this.loading = true;

                if (!this.userFunnelId) {
                    this.userFunnelId = localStorage.getItem("UserFunnelId");
                }
                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    code: this.mailChimpCode,
                    UserFunnelId: this.userFunnelId,
                });
                var config = {
                    method: "post",
                    url:
                        "" +
                        this.WebAPIURL +
                        "/api/AutoResponders/RedirectMailChimpAccountOath2?" +
                        data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        self.$router.push(
                            "/projectdetail/" + localStorage.getItem("UserFunnelId")
                        );
                        self.activeTab = "AutoResponder";
                        self.loading = false;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            updateAutoResponder: async function () {
                this.error = null;
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();
                var lstSelectedValues = "";
                if (
                    this.UserFunnelSteps[0].AutoResponderID == $themeConfig.app.GetResponse
                ) {
                    lstSelectedValues = this.UserFunnelSteps[0].ResponseCompainID;
                }
                if (
                    this.UserFunnelSteps[0].AutoResponderID == $themeConfig.app.SendLane
                ) {
                    lstSelectedValues = this.UserFunnelSteps[0].SendLaneListId;
                }
                if (
                    this.UserFunnelSteps[0].AutoResponderID == $themeConfig.app.MailChimp
                ) {
                    lstSelectedValues = this.UserFunnelSteps[0].MailChimpListId;
                }
                if (this.UserFunnelSteps[0].AutoResponderID == $themeConfig.app.AWeber) {
                    lstSelectedValues = this.UserFunnelSteps[0].AWeberListId;
                }

                var data = qs.stringify({
                    UserFunnelId: this.userFunnelId,
                    AutoResponderId: this.UserFunnelSteps[0].AutoResponderID,
                    lstSelectedValues: lstSelectedValues,
                    StepId: this.CurrStepID,
                });
                var config = {
                    method: "post",
                    url:
                        "" +
                        this.WebAPIURL +
                        "/api/AutoResponders/UpdateAutoResponder?" +
                        data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        //console.log(response.data);
                        // self.lstMailChimpAccount = response.data;
                        self.loading = false;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            refreshMailChimpLists: async function () {
                this.error = null;
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    UserFunnelId: this.userFunnelId,
                });
                var config = {
                    method: "get",
                    url:
                        "" +
                        this.WebAPIURL +
                        "/api/AutoResponders/MailChimpRefreshList?" +
                        data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        self.lstMailChimpAccount = response.data;
                        self.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.loading = false;
                    });
            },

            loadSendLaneAccount: async function () {
                const access_token = await jwt.getToken();
                this.$refs.sendLaneRules.validate().then((success) => {
                    if (success) {
                        this.loading = true;

                        var self = this;
                        var qs = require("qs");

                        var abdData = qs.stringify({
                            APIKey: this.SendLaneAPIKey,
                            HashKey: this.SendLaneHashKey,
                            SubDomain: this.SendLaneSubDomain,
                            UserFunnelId: this.userFunnelId,
                        });

                        var axios = require("axios");

                        console.log("abdData:: " + abdData);

                        var config = {
                            method: "post",
                            url:
                                "" +
                                this.WebAPIURL +
                                "/api/AutoResponders/VerifySendLaneAccount?" +
                                abdData,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: abdData,
                        };

                        axios(config)
                            .then(function (response) {
                                self.lstSendLaneAccount = response.data;
                                //self.SendLaneAPIKey = '';
                                //self.SendLaneHashKey = '';
                                //self.SendLaneSubDomain = '';
                                self.loading = false;
                            })
                            .catch(function (error) {
                                // console.log(error);
                                self.loading = false;
                            });
                    }
                });
            },

            loadAWeberAccount: async function (e) {
                const action = e.currentTarget.getAttribute("data-action");
                this.error = null;
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    UserFunnelId: this.userFunnelId,
                    AutoResponderId: this.UserFunnelSteps[0].AutoResponderID,
                    StepId: this.CurrStepID,
                    ActionName: action,
                });
                var config = {
                    method: "get",
                    url:
                        "" +
                        this.WebAPIURL +
                        "/api/AutoResponders/VerifyAWeberAccount?" +
                        data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        //console.log(response.data);
                        localStorage.setItem("UserFunnelId", self.userFunnelId);
                        window.location.href = response.data;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            verifyAWeberAccount: async function () {
                this.error = null;
                var self = this;
                this.loading = true;

                if (!this.userFunnelId) {
                    this.userFunnelId = localStorage.getItem("UserFunnelId");
                }
                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    code: this.aWebwerCode,
                    state: this.aWebwerState,
                    UserFunnelId: this.userFunnelId,
                });
                var config = {
                    method: "post",
                    url:
                        "" +
                        this.WebAPIURL +
                        "/api/AutoResponders/AWeberAccountRedirectURL?" +
                        data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        //console.log(response.data);
                        self.$router.push(
                            "/projectdetail/" + localStorage.getItem("UserFunnelId")
                        );
                        self.activeTab = "AutoResponder";

                        self.loading = false;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            onPopupTypeChange(event) {
                var optionText = event.target.value;
                console.log(optionText);
            },

            saveFormCode: async function () {
                this.error = null;
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    UserFunnelId: this.userFunnelId,
                    UserFunnelStepId: this.CurrStepID,
                    Id: this.webFormCode.Id,
                    FormHeading: this.webFormCode.FormHeading,
                    AddNameField: this.webFormCode.DisplayName,
                    IsNameRequiredField: this.webFormCode.RequiredName,
                    PopupType: this.webFormCode.PopupType,
                    RedirectionType: this.webFormCode.RedirectionType,
                });
                var config = {
                    method: "post",
                    url: "" + this.WebAPIURL + "/api/Projects/GenerateWebformCode?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        //console.log(response.data);
                        self.webFormCode = response.data;
                        self.loading = false;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            loginToSqrrindle: async function () {
                const access_token = await jwt.getToken();

                this.$refs.loginToSqrrindleRules[0].validate().then((success) => {
                    if (success) {
                        this.error = null;
                        var self = this;
                        this.loading = true;

                        var axios = require("axios");
                        var qs = require("qs");

                        var data = qs.stringify({
                            ProjectId: this.userFunnelId,
                            LoginEmail: this.loginEmail,
                            LoginPassword: this.loginPassword,
                            IsSendAutomationEmails: this.sendAutomationEmails,
                        });
                        var config = {
                            method: "post",
                            url:
                                "" +
                                this.WebAPIURL +
                                "/api/Projects/AuthenticateSqrindleUser?" +
                                data,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };

                        axios(config)
                            .then(function (response) {
                                self.loading = false;

                                var res = response.data.split("|");
                                if (res[0] === "success") {
                                    var successMessage =
                                        res[1] +
                                        " contact(s) imported ( " +
                                        res[2] +
                                        " New Contacts )";
                                    if (parseInt(res[1]) <= 0) {
                                        successMessage = "0 contact imported";
                                    }
                                    self.$swal({
                                        title: "Import Success!",
                                        //text: successMessage,
                                        icon: "success",
                                        customClass: {
                                            confirmButton: "btn btn-primary",
                                        },
                                        buttonsStyling: false,
                                    });
                                } else {
                                    self.$swal({
                                        title: "Import Failed!",
                                        text: " An error occurred while attempting to import your contacts, Please verify you have entered valid credentials!",
                                        icon: "error",
                                        customClass: {
                                            confirmButton: "btn btn-primary",
                                        },
                                        buttonsStyling: false,
                                    });
                                }
                            })
                            .catch(function (error) {
                                // console.log(error);
                                self.loading = false;
                            });
                    }
                });
            },

            onCopy: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Copied`,
                        icon: "CopyIcon",
                        variant: "success",
                        text: `You just copied the WEB FORM CODE to the clipboard!`,
                    },
                });
            },
            onError: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Error`,
                        icon: "CopyIcon",
                        variant: "error",
                        text: `Failed to copy the WEB FORM CODE to the clipboard!`,
                    },
                });
            },

            onPDFCopy: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Copied`,
                        icon: "CopyIcon",
                        variant: "success",
                        text: `Your PDF URL has been copied to clipboard!`,
                    },
                });
            },
            onPDFError: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Error`,
                        icon: "CopyIcon",
                        variant: "error",
                        text: `Failed to copy the PDF URL to the clipboard!`,
                    },
                });
            },

            onStepURLCopy: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Copied`,
                        icon: "CopyIcon",
                        variant: "success",
                        text: `Your Step URL has been copied to clipboard!`,
                    },
                });
            },
            onStepURLError: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Error`,
                        icon: "CopyIcon",
                        variant: "error",
                        text: `Failed to copy the Step URL to the clipboard!`,
                    },
                });
            },

            onPathCopy: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Copied`,
                        icon: "CopyIcon",
                        variant: "success",
                        text: `Your Project URL has been copied to clipboard!`,
                    },
                });
            },
            onPathError: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Error`,
                        icon: "CopyIcon",
                        variant: "error",
                        text: `Failed to copy the Project URL to the clipboard!`,
                    },
                });
            },

            copyTermToClipboard: function (e) {
                navigator.clipboard.writeText(this.termConditionsUrl).then(
                    () => {
                        this.ontermPathCopy();
                    },
                    (err) => {
                        this.ontermPathError();
                    }
                );
            },

            ontermPathCopy: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Copied`,
                        icon: "CopyIcon",
                        variant: "success",
                        text: `Your Term Conditions URL has been copied to clipboard!`,
                    },
                });
            },
            ontermPathError: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Error`,
                        icon: "CopyIcon",
                        variant: "error",
                        text: `Failed to copy the Term Conditions URL to the clipboard!`,
                    },
                });
            },

            copyPrivacyPolicyToClipboard: function (e) {
                navigator.clipboard.writeText(this.privacyUrl).then(
                    () => {
                        this.onPrivacyPathCopy();
                    },
                    (err) => {
                        this.onPrivacyPathError();
                    }
                );
            },

            onPrivacyPathCopy: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Copied`,
                        icon: "CopyIcon",
                        variant: "success",
                        text: `Your Privacy Policy URL has been copied to clipboard!`,
                    },
                });
            },
            onPrivacyPathError: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Error`,
                        icon: "CopyIcon",
                        variant: "error",
                        text: `Failed to copy the Privacy Policy URL to the clipboard!`,
                    },
                });
            },

            attachPDFMsg: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `PDF NOT FOUND`,
                        icon: "CopyIcon",
                        variant: "info",
                        text: `Please add a PDF in Project Settings!`,
                    },
                });
            },

            completeProjectMessage: function (e) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `SELECT TEMPLATE`,
                        icon: "CopyIcon",
                        variant: "info",
                        text: `Please complete selection!`,
                    },
                });
            },

            handleFileUpload() {
                console.log("file", this.$refs.file.files[0])

                //this.getFileInformation(this.$refs.file.files[0]).then((fileInfo) => {
                //    if (fileInfo) {
                //        console.log(fileInfo); // Output PDF information
                //    } else {
                //        console.log('File information not available');
                //    }
                //});
                this.FunnelPDFFile = null; // Clear the selected file
                if (this.$refs.file.files[0] && this.$refs.file.files[0].size > 20971521) {


                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Error`,
                            icon: "Error",
                            variant: "danger",
                            text: `File size is too large. Maximum size allowed is 20MB.`,
                        },
                    });

                }
                else {
                    this.FunnelPDFFile = this.$refs.file.files[0];
                }

            },

            getFileInformation(file) {
                return new Promise((resolve) => {
                    if (!file) {
                        resolve(null);
                    } else {
                        // Simulate asynchronous file information retrieval
                        setTimeout(() => {
                            // Access file information here, e.g., file.size, file.type, etc.
                            resolve(file);
                        }, 100); // Adjust the delay as needed
                    }
                });
            },

            closeProjectSettingsModal() {
                this.$bvModal.hide("projectSettingsModal");
            },

            closeTermsPrivacyPolicyModal() {
                this.$bvModal.hide("projectTermsPrivacyPolicy");
            },

            updateProjectSettings: async function () {
                const access_token = await jwt.getToken();
                var self = this;
                this.$refs.projectSettingsRules.validate().then((success) => {
                    if (success) {
                        this.error = null;

                        self.loading = true;

                        let formData = new FormData();

                        formData.append("Name", this.funnelDef.Name);
                        formData.append("TagGroup", null);
                        formData.append("FunnelPath", this.funnelDef.Path);
                        formData.append("Domain", this.funnelDef.Domain);
                        formData.append("FaviconPath", this.funnelDef.FaviconPath);
                        formData.append("HeadTrackingCode", this.funnelDef.HeadTrackingCode);
                        formData.append("BodyTrackingCode", this.funnelDef.BodyTrackingCode);
                        formData.append("Id", this.funnelDef.Id);
                        formData.append("FunnelPDFFileURL", this.funnelDef.PDFFileURL);
                        formData.append("IsTwoStepOptin", this.funnelDef.IsTwoStepOptin);
                        formData.append("IsEmailSending", this.funnelDef.IsEmailSending)
                        formData.append("file", this.FunnelPDFFile);

                        //axis
                        axis
                            .post(
                                "" + this.WebAPIURL + "/api/Projects/UpdateFunnelSettings",
                                formData,
                                {
                                    headers: {
                                        Authorization: "bearer " + access_token,
                                        "Content-Type": "x-www-form-urlencode",
                                    },
                                }
                            )
                            .then((response) => {
                                self.funnelName = self.funnelDef.Name;
                                self.funnelDef = response.data;
                                self.$nextTick(() => {
                                    self.$bvModal.hide("projectSettingsModal");
                                });
                                self.loading = false;
                            })
                            .catch((error) => {
                                this.errorMessage = error.message;
                                // console.error("There was an error!", error);
                            });
                    }
                });
            },

            updateTermPrivacyPolicy: async function () {
                const access_token = await jwt.getToken();
                this.$refs.projectTermsPrivacyPolicyRules.validate().then((success) => {
                    if (success) {
                        this.error = null;
                        var self = this;
                        this.loading = true;
                        let formData = new FormData();
                        formData.append("PrivacyPolicy", window.btoa(unescape(encodeURIComponent(this.funnelDef.PrivacyPolicy))));
                        formData.append("TermsConditions", window.btoa(unescape(encodeURIComponent(this.funnelDef.TermsConditions))));
                        formData.append("Id", this.funnelDef.Id);

                        //axis
                        axis
                            .post(
                                "" + this.WebAPIURL + "/api/Projects/UpdateTermPrivacyPolicy",
                                formData,
                                {
                                    headers: {
                                        Authorization: "bearer " + access_token,
                                        "Content-Type": "x-www-form-urlencode",
                                    },
                                }
                            )
                            .then((response) => {
                                self.funnelDef = response.data;

                                this.termConditionsUrl = this.funnelPath + "/terms";
                                this.privacyUrl = this.funnelPath + "/privacy";


                                self.$nextTick(() => {
                                    self.$bvModal.hide("projectTermsPrivacyPolicy");
                                });
                                self.loading = false;
                            })
                            .catch((error) => {
                                this.errorMessage = error.message;
                                // console.error("There was an error!", error);
                            });

                    }
                });
            },

            downloadHTML: async function (e) {
                const subDomain = e.currentTarget.getAttribute("data-subDomain");
                const stepid = e.currentTarget.getAttribute("data-stepid");

                this.error = null;
                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                var data = qs.stringify({
                    Id: stepid,
                    DomainName: subDomain,
                });

                var script =
                    '<script type="text/javascript" src="' +
                    subDomain +
                    "API/Funnel/Get/" +
                    stepid +
                    '"><';
                var scriptEnd = "/script>";
                var HTML =
                    '<style>.contaner_body{font-family: Helvetica,Arial,"Lucida Grande",sans-serif !important;background: #fff !important;border: 1px solid #e4e5e7;border-radius: 2px;padding: 20px;position: relative;}table{width: 100%;border: 1px solid #e4e5e7;}.td_label {width: 33.33%;color: #6a6c6f;border: 1px solid #e4e5e7;}.td_value {width: 50%;color: #6a6c6f;border: 1px solid #e4e5e7;}.td_full_width {width: 100%;}.td_nodata {text-align:center;}label {display: inline-block;max-width: 100%;margin-bottom: 5px;margin-left:5px;}</style>' +
                    script +
                    scriptEnd +
                    "";
                var fileName = "Funnels.html";
                if (this.funnelName) {
                    fileName = this.funnelName + ".html";
                }
                var download = require("downloadjs");
                download(HTML, fileName, "text/html");
                this.loading = false;
            },
            resetProjectTemplate: async function (e) {
                var UserFunnelId = e.currentTarget.getAttribute("data-UserFunnelId");
                //var templateTypeId = e.currentTarget.getAttribute("data-templateTypeId");

                //data-templateTypeId
                var UserFunnelStepId = e.currentTarget.getAttribute(
                    "data-UserFunnelStepId"
                );
                var UserFunnelStepVariationId = e.currentTarget.getAttribute(
                    "data-UserFunnelStepVariationId"
                );

                this.error = null;
                var self = this;

                var axios = require("axios");
                var qs = require("qs");
                const access_token = await jwt.getToken();

                this.CurrentStepId = UserFunnelStepId;
                var data = qs.stringify({
                    UserFunnelStepVariationId: UserFunnelStepVariationId,
                    UserFunnelStepId: UserFunnelStepId,
                    UserFunnelId: UserFunnelId,
                });
                this.$swal({
                    title: "Reset Template",
                    html: "Are you sure to Reset your template? <br/><br/> It will reset everything which is not reversible",
                    //icon: "info",
                    showCancelButton: true,
                    confirmButtonText: "Reset",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        self.loading = true;
                        var config = {
                            method: "post",
                            url:
                                "" +
                                this.WebAPIURL +
                                "/api/Projects/ResetProjectTemplate?" +
                                data,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };

                        axios(config)
                            .then(function (response) {
                                self.fetchProjectsData();
                            })
                            .catch(function (error) {
                                // console.log(error);
                                self.loading = false;
                            });
                    }
                });
            },
            openPaymentProcessor(event) {
                var paymentProcessor = event.target.value;
                this.PaymentMethodId = paymentProcessor;
                //var dataName = event.target.dataName;

                var dataName = event.target.options[event.target.options.selectedIndex].getAttribute('dataName')
                if (dataName != null) {

                }
                if (dataName == this.ProductDetail.FFProduct.PaymentMethodCode) {
                    this.CheckoutTempId = this.ProductDetail.FFProduct.TemplateId;
                }
                else {
                    this.CheckoutTempId = null;
                }
                this.PaymentMethod = dataName;

                this.LoadCheckoutPageTemplates();
            },

            ResetCheckoutTemplate: async function (e) {
                this.SalePageTemplateName = this.PaymentMethod;
                this.CheckoutTempId = null;
                this.SalePageVariation = null;
                this.LoadCheckoutPageTemplates();
            },

            UseThisCheckoutTemplate: async function (e) {
                this.SalePageTemplateName = e.currentTarget.getAttribute("data-TemplateName");
                this.SalePageVariation = e.currentTarget.getAttribute("data-TemplateVariation");
                this.SalePageTemplateId = e.currentTarget.getAttribute("data-value");
                this.CheckoutTempId = e.currentTarget.getAttribute("data-value");
                this.LoadCheckoutPageTemplates();
            },
            LoadCheckoutPageTemplates: async function (e) {
                this.loading = true;
                var self = this;
                this.SalePageTemplates = null;

                const access_token = await jwt.getToken();
                var axios = require("axios");
                var qs = require("qs");

                var data = qs.stringify({
                    TemplateId: this.CheckoutTempId,
                    TemplateName: this.PaymentMethod
                });
                var config = {
                    method: "get",
                    url: "" + this.WebAPIURL + "/api/projects/GetCheckoutTemplates?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        self.SalePageTemplates = response.data;
                        self.loading = false;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },
        },
        destroyed() {
            //document.querySelector('a[href*="/projects"]').parentNode.classList.remove("active");

        },
    };
</script>
<style lang="scss">
    @import "@core/scss/vue/libs/quill.scss";
</style>
<style scoped>
    .mr {
        margin-bottom: 0.5rem !important;
    }
    /*.btn-icon svg.feather {
        width: 22px !important;
        height: 22px !important;
    }*/
    .icon-color {
        color: #28c76f;
    }
</style>